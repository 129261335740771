import React, { useEffect } from "react";
import {
  Col,
  Row,
  Form,
  FormInput,
  Button,
  FormTextarea,
  FormSelect
} from "shards-react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { updateRoleAction } from "../../store/Actions/RolesActions";

function UpdateRole({ open, handleClose, id }) {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const handleUpdateRole = async values => {
    setLoading(true);

    const reqObj = {
      data: {
        ...values,
        roleID: parseInt(id)
      }
    };
    console.log("response id", reqObj);
    const response = await dispatch(updateRoleAction(reqObj));
    if (response == 1) {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <h5>Update Role</h5>{" "}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Formik
              initialValues={{
                name: "",
                description: ""
              }}
              validationSchema={yup.object({
                name: yup.string().required("Consultant's name is required"),

                description: yup.string().required("description is required")
              })}
              onSubmit={values => {
                console.log("values", values);
                handleUpdateRole(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form>
                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="name"> Name</label>
                      <FormInput
                        id="name"
                        type="text"
                        placeholder="Please enter name"
                        value={values.name}
                        onChange={handleChange("name")}
                        onBlur={handleBlur("name")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.name && errors.name}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <label htmlFor="description">Description</label>
                      <FormTextarea
                        id="description"
                        rows="3"
                        value={values.description}
                        onChange={handleChange("description")}
                        onBlur={handleBlur("description")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.description && errors.description}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="my-3" style={{ textAlign: "right" }}>
                      <Button outline theme="info" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        disabled={loading}
                        className="mx-2 "
                        onClick={handleSubmit}
                      >
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                        &nbsp; Update Role
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default UpdateRole;
