import axios from "./index";

export const _getAllConsultants = () => {
  return axios.get("/v1/admin/get/consultant/-");
};

export const _createConsultant = reqObj => {
  return axios.post("/v1/admin/create/company", reqObj);
};

export const _getConsultant = consultantID => {
  return axios.get(`/v1/admin/get/consultant/${consultantID}`);
};
