import React from "react";
import { Row, Col } from "shards-react";
import { useSelector } from "react-redux";

function SummaryTab({ data }) {
  const company = useSelector(state => state.Companies.company);
  console.log("sunmmary ");
  return (
    <div className="p-4">
      <Row>
        <Col>
          <h5>Profile Summary</h5>{" "}
        </Col>{" "}
      </Row>{" "}
      <Row>
        <Col>
          <p>
            Name:&nbsp;
            <strong className="font-weight-bold">{data && data.name}</strong>
            <br />
            Company's RC number:&nbsp;
            <strong className="font-weight-bold">{data && data.rc}</strong>
            <br />
            Year of Incorporation:&nbsp;
            <strong className="font-weight-bold">
              {data && data.yearofinc}
            </strong>
            <br />
            Year of Operation:&nbsp;
            <strong className="font-weight-bold">
              {data && data.yearofop}
            </strong>
            <br />
            Company's Email:&nbsp;
            <strong className="font-weight-bold">{data && data.email}</strong>
            <br />
            Company's Phone number:&nbsp;
            <strong className="font-weight-bold">{data && data.phone}</strong>
            <br />
            Company's Address:&nbsp;
            <strong className="font-weight-bold">{data && data.address}</strong>
            <br />
            Company's LGA:&nbsp;
            <strong className="font-weight-bold">{data && data.lg}</strong>
          </p>
        </Col>
      </Row>
    </div>
  );
}

SummaryTab.defaultProps = {
  data: {
    id: 4,
    name: "Lumi Tech",
    yearofinc: "2021-02-01",
    yearofop: "2021-02-03",
    lg: "IKD",
    address: "ikorodu",
    area: null,
    areaused: null,
    opprocess: null,
    email: "samthiawoh@gmail.com",
    phone: "9090909090",
    consultant: null,
    envpolicy: null,
    coord: null,
    techreport: null,
    dateofcapture: null,
    employees: null,
    medicalpartner: null,
    dateCreated: "1612209875",
    status: 0,
    creator: "admin",
    sectors: null,
    haswaterhydrant: null,
    technicaldetails: null,
    complianceproblem: null,
    typoshortcomming: null,
    rc: "123456",
    companyID: "36YIF57M"
  }
};

export default SummaryTab;
