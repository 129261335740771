import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import IndustryProfileCard from "../../components/industry-manager/IndustryProfileCard";
import IndustryProfileDetails from "../../components/industry-manager/IndustryProfileDetails";
import { useDispatch } from "react-redux";
import { getCompany } from "../../store/Actions/CompanyActions";
import { useSelector } from "react-redux";
import { getConsultant } from "../../store/Actions/ConsultantActions";
import ConsultantProfileDetails from "./ConsultantProfileDetails";

function ConsultantDetails({ location: { state }, ...props }) {
  const [compID, setCompID] = useState("");
  const dispatch = useDispatch();
  const consultant = useSelector(state => state.Consultants.consultant);
  console.log("yo yo yo yeaaaaaaaaaaa", consultant);
  const { id } = useParams();

  useEffect(() => {
    try {
      dispatch(getConsultant(id));
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-2">
        <PageTitle
          title={consultant && consultant.companyName}
          subtitle=""
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="4">
          <IndustryProfileCard industryDetails={consultant} />
        </Col>

        <Col lg="8">
          <ConsultantProfileDetails consultantDetails={state} />
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(ConsultantDetails);
