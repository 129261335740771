import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Card, CardBody, CardFooter, Button } from "shards-react";
// import image from "../../images/content-management/coy.png";
import { MdEmail, MdLocalPhone, MdLocationOn } from "react-icons/md";

const IndustryManagerList = ({ data }) => {
  return (
    <>
      {data &&
        data.map((post, idx) => (
          <Col lg="4" md="6" sm="12" className="mb-4" key={idx}>
            <Card small className="card-post h-100">
              <div
                className="card-post__image"
                // style={{ backgroundImage: `url('${post.backgroundImage}')` }}
                style={{
                  backgroundImage: `url('${require("../../images/content-management/coy.png")}')`
                }}
              />
              <CardBody>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ fontSize: ".8rem" }}>
                    {" "}
                    RC: &nbsp;
                    {post.rc}
                  </span>

                  <span style={{ fontSize: ".8rem" }}>
                    {" "}
                    Company ID: &nbsp;
                    {post.companyID}
                  </span>
                </div>
                <h5 className="card-title">
                  <Link
                    to={{
                      pathname: `/company-manager/details/${post.id}`,
                      state: post
                    }}
                    className="text-fiord-blue"
                    style={{ fontSize: "1.5rem" }}
                  >
                    {post.name}
                  </Link>
                  {/* <a className="text-fiord-blue" href="#">
                    {post.name}
                  </a> */}
                </h5>
                {/* <span style={{ fontSize: ".8rem" }}>
                  {" "}
                  Company ID: &nbsp;
                  <strong>{post.companyID}</strong>
                </span> */}
                <p>
                  <MdEmail />: <span className="">{post.email}</span> <br />{" "}
                  <MdLocalPhone />: <span className="">{post.phone}</span>{" "}
                  <br /> <MdLocationOn />:{" "}
                  <span className="">{post.address}</span> <br />
                </p>
              </CardBody>
              <CardFooter className="text-muted border-top py-3">
                <span className="d-inline-block">
                  <Link
                    to={{
                      pathname: `/company-manager/details/${post.id}`,
                      state: post
                    }}
                  >
                    View Details
                  </Link>
                </span>
              </CardFooter>
            </Card>
          </Col>
        ))}
    </>
  );
};

export default IndustryManagerList;
