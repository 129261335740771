import { GET_PAYMENTS, GET_REPORTS } from "../types";

const initialState = {
  reports: []
};

export default function(state = initialState, actions) {
  switch (actions.type) {
    case GET_REPORTS:
      return {
        ...state,
        reports: actions.payload
      };
    default: {
      return state;
    }
  }
}
