import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  FormRadio
} from "shards-react";
import * as Yup from "yup";
import { Formik, FieldArray, getIn } from "formik";
import { FiPlusCircle, FiMinusCircle, FiDivide } from "react-icons/fi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CarbonEmissionsForm = ({ state }) => {
  return (
    <Card small className="">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Effort at Reducing Carbon Emission</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Formik
                initialValues={{
                  desc: ""
                }}
                validationSchema={Yup.object({
                  desc: Yup.string()
                })}
                onSubmit={values => {
                  console.log("form submit", values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <Form>
                    <Row>
                      <Col md="4">
                        <label htmlFor="desc">
                          A. &nbsp; Itemize efforts at reducing carbon emissions
                        </label>
                      </Col>
                      <Col md="8">
                        <FormTextarea
                          placeholder="Please describe efforts at reducing carbon emissions"
                          id="desc"
                          rows="5"
                        />
                      </Col>
                    </Row>
                    <hr />

                    <Row>
                      <Col className="text-right ">
                        <Button theme="accent" onClick={handleSubmit}>
                          Save
                        </Button>
                        <Button
                          outline
                          className="my-3 mx-2"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Preview Details
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};
CarbonEmissionsForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

CarbonEmissionsForm.defaultProps = {
  title: "Account Details"
};

export default CarbonEmissionsForm;
