import axios from "./index";

export const _getRole = () => {
  return axios.get(`/v1/admin/get/role`);
};

export const _createRole = reqObj => {
  return axios.post("/v1/admin/create/role", reqObj);
};

export const _updateRole = reqObj => {
  return axios.put("/v1/admin/update/role", reqObj);
};

export const _assignRole = reqObj => {
  return axios.post("/v1/admin/assign/role", reqObj);
};

export const _getRoleModules = roleID => {
  return axios.get(`/v1/admin/get/roletag/${roleID}`);
};

export const _assignModule = reqObj => {
  return axios.post("/v1/admin/add/roletag", reqObj);
};

// export const _closeReport = reqObj => {
//   return axios.put(`/v1/admin/close-report`, reqObj);
// };
