import axios from "./index";

export const _getAllAdmin = () => {
  return axios.get(`/v1/admin/get/staff/-`);
};

export const _createStaff = reqObj => {
  return axios.post("/v1/admin/create/staff", reqObj);
};

export const _updatePassword = reqObj => {
  return axios.put("/v1/admin/update/password", reqObj);
};

export const _getSession = () => {
  return axios.get("/v1/admin/get-session");
};
