import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, SubDefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import IndustryManager from "./views/IndustryManager";
import ConsultantManager from "./views/ConsultantManager";
import IndustryDetails from "./views/IndustryManager/IndustryDetails";
import ReportNoise from "./views/ReportNoise";
import ActivitiesLog from "./views/ActivitiesLog";
import AddNewCompany from "./views/IndustryManager/AddNewCompany";
import Demo from "./views/Demo";
import Legal from "./views/IndustryManager/Legal";
import ProductionDetails from "./views/IndustryManager/ProductionDetails";
import WarehouseDetails from "./views/IndustryManager/WarehouseDetails";
import EnergyDetails from "./views/IndustryManager/EnergyDetails";
import CarbonEmissions from "./views/IndustryManager/CarbonEmissions";
import StorageTank from "./views/IndustryManager/StorageTank";
import WaterDetails from "./views/IndustryManager/WaterDetails";
import PollutionManagement from "./views/IndustryManager/PollutionManagement";
import BakeHouse from "./views/IndustryManager/BakeHouse";
import WasteManagement from "./views/IndustryManager/WasteManagement";
import TechnicalDetails from "./views/IndustryManager/TechnicalDetails.js";
import Compliance from "./views/IndustryManager/Compliance";
import Typographical from "./views/IndustryManager/Typographical";
import Admin from "./views/Admin";
import Attachement from "./views/IndustryManager/Attachement";
import Payments from "./views/Payments";
import Reports from "./views/Reports";
import InvoiceList from "./views/Payments/InvoiceList";
import { ContactSupportOutlined } from "@material-ui/icons";
import ConsultantDetails from "./views/ConsultantManager/ConsultantDetails";
import Roles from "./views/Roles";
import DocRequests from "./views/DocRequests";

// let state, id;
// try {
//   id = JSON.parse(localStorage.getItem("activeID"));
//   // id = state.id;
// } catch (error) {
//   console.log(error.message);
// }

// const { id } = state;
// console.log("this state is gold", state);
// console.log("this id", id);
export default id => [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/company-manager" />
  },
  {
    path: "/blog-overview",
    exact: true,
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/payments",
    exact: true,
    layout: DefaultLayout,
    component: Payments
  },
  {
    path: "/payments/:id",
    exact: true,
    layout: DefaultLayout,
    component: InvoiceList
  },
  {
    path: "/reports",
    exact: true,
    layout: DefaultLayout,
    component: Reports
  },
  {
    path: "/access-level",
    exact: true,
    layout: DefaultLayout,
    component: Roles
  },
  {
    path: "/consultants",
    exact: true,
    layout: DefaultLayout,
    component: ConsultantManager
  },
  {
    path: "/consultants/:id",
    exact: true,
    layout: DefaultLayout,
    component: ConsultantDetails
  },
  {
    path: "/company-manager",
    exact: true,
    layout: DefaultLayout,
    component: IndustryManager
  },

  {
    path: "/company-manager/details/:id",
    exact: true,
    layout: SubDefaultLayout,
    component: IndustryDetails
  },
  {
    path: "/company-manager/details/:id/legal",
    exact: true,
    layout: SubDefaultLayout,
    component: Legal
  },
  {
    path: `/company-manager/details/:id/prod-details`,
    exact: true,
    layout: SubDefaultLayout,
    component: ProductionDetails
  },
  {
    path: `/company-manager/details/:id/warehouse-details`,
    exact: true,
    layout: SubDefaultLayout,
    component: WarehouseDetails
  },
  {
    path: `/company-manager/details/:id/energy-details`,
    exact: true,
    layout: SubDefaultLayout,
    component: EnergyDetails
  },
  {
    path: `/company-manager/details/:id/carbon-emissions`,
    exact: true,
    layout: SubDefaultLayout,
    component: CarbonEmissions
  },
  {
    path: `/company-manager/details/:id/storage-tank`,
    exact: true,
    layout: SubDefaultLayout,
    component: StorageTank
  },
  {
    path: `/company-manager/details/:id/water-details`,
    exact: true,
    layout: SubDefaultLayout,
    component: WaterDetails
  },

  {
    path: `/company-manager/details/:id/pollution-mgmt`,
    exact: true,
    layout: SubDefaultLayout,
    component: PollutionManagement
  },
  {
    path: `/company-manager/details/:id/bakehouse`,
    exact: true,
    layout: SubDefaultLayout,
    component: BakeHouse
  },
  {
    path: `/company-manager/details/:id/waste-mgmt`,
    exact: true,
    layout: SubDefaultLayout,
    component: WasteManagement
  },
  {
    path: `/company-manager/details/:id/other-tech`,
    exact: true,
    layout: SubDefaultLayout,
    component: TechnicalDetails
  },
  {
    path: `/company-manager/details/:id/other-compliance`,
    exact: true,
    layout: SubDefaultLayout,
    component: Compliance
  },
  {
    path: `/company-manager/details/:id/typographical-details`,
    exact: true,
    layout: SubDefaultLayout,
    component: Typographical
  },
  {
    path: `/company-manager/details/:id/attachements`,
    exact: true,
    layout: SubDefaultLayout,
    component: Attachement
  },
  {
    path: "/report-noise",
    exact: true,
    layout: SubDefaultLayout,
    component: ReportNoise
  },
  {
    path: "/activities-log",
    exact: true,
    layout: DefaultLayout,
    component: ActivitiesLog
  },
  {
    path: "/doc-requests",
    exact: true,
    layout: DefaultLayout,
    component: DocRequests
  },
  {
    path: "/admin",
    exact: true,
    layout: DefaultLayout,
    component: Admin
  },
  {
    path: "/user-profile-lite",
    exact: true,
    layout: SubDefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    exact: true,
    layout: SubDefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: SubDefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: SubDefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: SubDefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: SubDefaultLayout,
    component: BlogPosts
  }
  // {
  //   path: "/report-noise",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: ReportNoise
  // },

  // {
  //   path: "/user-profile-lite",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: UserProfileLite
  // },
  // {
  //   path: "/add-new-post",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: AddNewPost
  // },
  // {
  //   path: "/errors",
  //   layout: DefaultLayout,
  //   component: Errors
  // },
  // {
  //   path: "/components-overview",
  //   layout: DefaultLayout,
  //   component: ComponentsOverview
  // },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // },
  // {
  //   path: "/blog-posts",
  //   layout: DefaultLayout,
  //   component: BlogPosts
  // }
];
