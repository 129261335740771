import { _getAllLogs, _getLogCategory } from "../../api/ActivitiesLogRequest";
import { GET_ALL_LOGS } from "../types";
import { NotificationManager } from "react-notifications";

export const getAllLogs = () => {
  return async dispatch => {
    try {
      const { data } = await _getAllLogs();
      const res = await _getLogCategory();
      // console.log("logs  login ", res);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_ALL_LOGS, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      // console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};
