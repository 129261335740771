import { GET_ALL_ADMIN } from "../types";

const initialState = {
  alladmin: []
};

export default function(state = initialState, actions) {
  switch (actions.type) {
    case GET_ALL_ADMIN:
      return {
        ...state,
        alladmin: actions.payload
      };
    default: {
      return state;
    }
  }
}
