import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation
} from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "./routes";
import subroutes from "./subroutes";
import withTracker from "./withTracker";
import Signin from "./views/Authentication/Signin";
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import Admin from "./views/Admin";
import ActivitiesLog from "./views/ActivitiesLog";

export default () => {
  const token = sessionStorage.getItem("token");
  // const thisroute = useLocation();
  // console.log("this is the route", document.location.pathname);

  const [state, setState] = React.useState({ id: 0 });
  const updateUrlIndexFromStorage = () => {
    const id = parseInt(localStorage.getItem("activeID"));

    setState({ id });
  };

  //remove all console elements
  console.log = function() {};

  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      {/* div replaced with switch; revert if it poses routing problem */}
      <Switch>
        <Route path="/signin" component={Signin} />

        {routes(state.id).map((route, index) => {
          if (token) {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={props => {
                  return (
                    <route.layout
                      {...props}
                      onNavigate={updateUrlIndexFromStorage}
                    >
                      <route.component {...props} />
                    </route.layout>
                  );
                }}
              />
            );
          } else {
            return <Redirect to="/signin" />;
          }
        })}
        {/* 
        {subroutes.map((route, index) => {
          if (token) {
            return (
              <Route
                key={index}
                path={`${document.location.pathname}/${route.path}`}
                exact={route.exact}
                component={props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                }}
              />
            );
          } else {
            return <Redirect to="/signin" />;
          }
        })} */}
      </Switch>
    </Router>
  );
};
