import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import IndustryProfileCard from "../../components/industry-manager/IndustryProfileCard";
import IndustryProfileDetails from "../../components/industry-manager/IndustryProfileDetails";
import { useDispatch } from "react-redux";
import { getCompany } from "../../store/Actions/CompanyActions";
import { useSelector } from "react-redux";

function IndustryDetails({ location: { state }, ...props }) {
  const [compID, setCompID] = useState("");
  const dispatch = useDispatch();
  const company = useSelector(state => state.Companies.company);
  const loc = useLocation();

  // useEffect(() => {
  //   localStorage.setItem("activeID", props.match.params.id);
  // }, [props.match.params.id]);

  useEffect(() => {
    console.log("reached");
    // setCompID(state.companyID);
    try {
      localStorage.setItem("activeID", state.id);
      localStorage.setItem("activeCompany", JSON.stringify(state));
    } catch (error) {
      console.log(error);
    }
    // if (!compID) {
    //   async function getSingleCompany() {
    //     await dispatch(getCompany(compID));
    //   }
    //   getSingleCompany();
    // }
  }, [state]);

  // useEffect(() => {
  //   async function getSingleCompany() {
  //     await dispatch(getCompany(companyID));
  //   }
  //   getSingleCompany();
  // }, []);

  // const saveCompanyID = () => {
  //   localStorage.setItem("activeID", props.match.params.id);
  // };

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-2">
        <PageTitle
          title={state && state.companyName}
          subtitle=""
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="4">
          <IndustryProfileCard industryDetails={state} />
        </Col>

        <Col lg="8">
          <IndustryProfileDetails industryDetails={state} />
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(IndustryDetails);
