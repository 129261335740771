import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button } from "shards-react";
import UpdateStatus from "../Payments/UpdateStatus";
import { getPaymentsInvoice } from "../../store/Actions/PaymentsActions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function ConsultantInvoice() {
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
  const [rowStatusData, setRowStatusData] = useState(null);
  const dispatch = useDispatch();
  const consultantInvoice = useSelector(state => state.Payments.coyInvoice);
  const { id } = useParams();

  const toggleUpdateStatusModal = rowData => {
    setOpenUpdateStatusModal(true);
    setRowStatusData(rowData);
  };

  const handleCloseModal = () => {
    setOpenUpdateStatusModal(val => !val);
  };

  useEffect(() => {
    async function callGetPayments() {
      const response = await dispatch(getPaymentsInvoice(id));
    }

    callGetPayments();
  }, []);

  const formatMoney = currency => {
    const number = Number(currency) || 0;
    return number.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN"
    });
  };

  return (
    <div>
      <UpdateStatus
        open={openUpdateStatusModal}
        rowStatusData={rowStatusData}
        handleClose={handleCloseModal}
      />
      <MaterialTable
        title="Invoice"
        columns={[
          // { title: "SN", field: "id" },
          { title: "Company ID", field: "companyID" },
          { title: "Bill ID", field: "billID" },
          { title: "Bill Name", field: "billName" },
          {
            title: "Amount",
            field: "amount",
            render: rowData => formatMoney(rowData.amount)
          },

          {
            title: "Status",
            field: "status",
            render: rowData =>
              rowData.status == "0" ? (
                <span
                  className="px-2 py-1"
                  style={{
                    borderRadius: 10,
                    border: "1px solid green",
                    fontSize: ".8rem"
                  }}
                >
                  Unapproved
                </span>
              ) : (
                <span
                  className="px-2 py-1"
                  style={{
                    borderRadius: 10,
                    backgroundColor: "green",
                    color: "white",
                    fontSize: ".8rem"
                  }}
                >
                  Approved
                </span>
              )
          },
          { title: "Creator", field: "creator" },
          {
            title: "Date",
            field: "transDate",
            render: rowData =>
              `${moment(rowData.transDate * 1000).format("DD/MM/YYYY")}`
          },
          {
            title: "Actions",
            field: "",
            render: rowData => (
              <div className="d-flex">
                <Link
                  className="btn btn-primary btn-sm"
                  to={{
                    pathname: `/payments/${rowData.companyID}`
                    //   state: rowData,
                  }}
                >
                  View Invoice
                </Link>
                &nbsp;
                <Button onClick={() => toggleUpdateStatusModal(rowData)}>
                  Update Status
                </Button>
              </div>
            )
          }
        ]}
        data={consultantInvoice}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF"
          },
          pageSize: 10
        }}
      />
    </div>
  );
}

export default ConsultantInvoice;
