import { NotificationManager } from "react-notifications";
import {
  _createStaff,
  _getAllAdmin,
  _updatePassword,
  _getSession
} from "../../api/AdminRequest";
import { GET_ALL_ADMIN } from "../types";

export const getAllAdminAction = () => {
  return async dispatch => {
    try {
      const { data } = await _getAllAdmin();
      console.log("success login ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      return -1;
    }
  };
};

export const createStaffAction = obj => {
  return async dispatch => {
    try {
      const {
        fullname,
        username,
        phone,
        address,
        email,
        password,
        gender,
        usertype,
        staffID,
        unit
      } = obj;

      const reqObj = {
        data: {
          fullname,
          username,
          phone,
          address,
          email,
          password,
          gender,
          usertype,
          staffID,
          unit
        }
      };
      const { data } = await _createStaff(reqObj);
      console.log("success created ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch(getAllAdminAction());
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      return -1;
    }
  };
};

export const updatePasswordAction = obj => {
  return async dispatch => {
    try {
      const { oldpassword, newpassword } = obj;

      const reqObj = {
        data: {
          oldpassword,
          newpassword
        }
      };
      const { data } = await _updatePassword(reqObj);
      console.log("success created ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success("Password Updated", "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const getSession = () => {
  return async dispatch => {
    try {
      const { data } = await _getSession();
      console.log("session login ", data);
      if (data.error.status == 1) {
        // NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        // dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      return -1;
    }
  };
};
