import axios from "./index";

export const _getInvoice = companyID => {
  return axios.get(`/v1/get/payments/${companyID || "-"}`);
};

export const _getAllInvoice = () => {
  return axios.get(`/v1/get/payments/-`);
};

export const _createInvoice = reqObj => {
  return axios.post("/v1/admin/create-invoice", reqObj);
};

export const _updateInvoice = reqObj => {
  return axios.put("/v1/admin/update-invoice", reqObj);
};

export const _viewProof = refID => {
  return axios.get(`v1/admin/get/doc/-/${refID}/-`);
};
