import React, { useState } from "react";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button } from "shards-react";
import UpdateStatus from "./UpdateStatus";
import { Spinner } from "react-bootstrap";
import {
  updateInvoiceAction,
  viewProofAction
} from "../../store/Actions/PaymentsActions";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../api";

function InvoiceDataTable({ payments }) {
  const dispatch = useDispatch();
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
  const [rowStatusData, setRowStatusData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [proofLoader, setProofLoader] = useState(false);

  const toggleUpdateStatusModal = rowData => {
    setOpenUpdateStatusModal(true);
    setRowStatusData(rowData);
  };

  const handleCloseModal = () => {
    setOpenUpdateStatusModal(val => !val);
  };

  const formatMoney = currency => {
    const number = Number(currency) || 0;
    return number.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN"
    });
  };

  const handleApprovePayment = async billID => {
    console.log("hey");
    setLoading(true);
    const vals = {
      billID,
      status: "1"
    };

    let response = await dispatch(updateInvoiceAction(vals));

    if (response) {
      setLoading(false);
    }
  };

  const handleViewProofPayment = async refID => {
    if (refID) {
      setProofLoader(true);
      let response = await dispatch(viewProofAction(refID));

      if (response) {
        setProofLoader(false);
        if (response != -1 && response.length > 0) {
          const URL = `${baseUrl}/${response[0].url}`;
          let a = document.createElement("a");
          a.target = "_blank";
          a.href = URL;
          a.click();
        }
      }
    }
  };

  return (
    <div>
      <UpdateStatus
        open={openUpdateStatusModal}
        rowStatusData={rowStatusData}
        handleClose={handleCloseModal}
      />
      <MaterialTable
        title="Invoice"
        columns={[
          { title: "SN", field: "id" },
          { title: "Company ID", field: "companyID" },
          { title: "Bill ID", field: "billID" },
          { title: "Bill Name", field: "billName" },
          {
            title: "Amount",
            field: "amount",
            render: rowData => formatMoney(rowData.amount)
          },

          {
            title: "Status",
            field: "status",
            render: rowData =>
              rowData.status == "0" ? (
                <span
                  className="px-2 py-1"
                  style={{
                    borderRadius: 10,
                    border: "1px solid green",
                    fontSize: ".8rem"
                  }}
                >
                  Unapproved
                </span>
              ) : (
                <span
                  className="px-2 py-1"
                  style={{
                    borderRadius: 10,
                    backgroundColor: "green",
                    color: "white",
                    fontSize: ".8rem"
                  }}
                >
                  Approved
                </span>
              )
          },
          { title: "Creator", field: "creator" },
          {
            title: "Date",
            field: "transDate",
            render: rowData =>
              `${moment(rowData.transDate * 1000).format("DD/MM/YYYY")}`
          },
          {
            title: "Actions",
            field: "",
            render: rowData => (
              <div className="d-flex">
                <Link
                  className="btn btn-primary btn-sm"
                  to={{
                    pathname: `/payments/${rowData.companyID}`
                    //   state: rowData,
                  }}
                >
                  View Invoice
                </Link>
                &nbsp;
                {/* <Button onClick={() => toggleUpdateStatusModal(rowData)}>
                  Update Status
                </Button> */}
                <Button
                  disabled={loading}
                  onClick={() => handleApprovePayment(rowData.billID)}
                >
                  Approve Payment
                </Button>
                &nbsp;
                {rowData.proof ? (
                  <Button
                    disabled={proofLoader}
                    onClick={() => handleViewProofPayment(rowData.proof)}
                  >
                    View Proof
                  </Button>
                ) : (
                  <Button> No Proof yet!</Button>
                )}
              </div>
            )
          }
        ]}
        data={payments}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF"
          },
          pageSize: 10
        }}
      />
    </div>
  );
}

// [
//     {
//       id: 1,
//       billID: "55CKSFGS0YQG",
//       amount: "6000",
//       status: 1,
//       proof: null,
//       companyID: "F1DTFV1W",
//       transDate: "1612948060",
//       creator: "admin",
//       billName: "General Maintenance",
//       details: "You guys need to pay us for pending accidence",
//       approvedate: "1612951390",
//       approvedby: "admin"
//     },
//     {
//       id: 1,
//       billID: "55CKSFGS0YQG",
//       amount: "6000",
//       status: 1,
//       proof: null,
//       companyID: "F1DTFV1W",
//       transDate: "1612948060",
//       creator: "admin",
//       billName: "General Maintenance",
//       details: "You guys need to pay us for pending accidence",
//       approvedate: "1612951390",
//       approvedby: "admin"
//     }
//   ]

export default InvoiceDataTable;
