import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  // Modal,
  // ModalHeader,
  // ModalBody,
  Form,
  FormInput,
  Button,
  FormTextarea,
  FormSelect
  // ModalFooter
} from "shards-react";
import { useDispatch } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { createInvoiceAction } from "../../store/Actions/PaymentsActions";
import { closeReport } from "../../store/Actions/ReportActions";

function CloseReportModal({ open, handleClose, data }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  console.log("closereport ", data);

  const handleSubmit = async val => {
    setLoading(true);
    const response = await dispatch(closeReport(val));
    if (response == 1) {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        {" "}
        <h4>Close Report</h4>{" "}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Formik
              initialValues={{
                reportID: `${data && data.cID}`,
                comment: "",
                responder: ""
              }}
              validationSchema={yup.object({
                responder: yup.string().required("Name is required"),
                comment: yup.string().required("Comment is required")
              })}
              onSubmit={values => {
                console.log("values", values);
                handleSubmit(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form>
                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="responder">Name</label>
                      <FormInput
                        id="responder"
                        type="text"
                        placeholder="Responders name"
                        value={values.responder}
                        onChange={handleChange("responder")}
                        onBlur={handleBlur("responder")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.responder && errors.responder}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="comment">Comment</label>

                      <FormTextarea
                        id="comment"
                        rows="3"
                        value={values.comment}
                        onChange={handleChange("comment")}
                        onBlur={handleBlur("comment")}
                        placeholder="Comment"
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.comment && errors.comment}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ textAlign: "right" }}>
                      <Button outline theme="info" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        className="mx-2 my-2"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default CloseReportModal;
