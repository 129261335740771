import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { getAllReports } from "../../store/Actions/ReportActions";

function DocRequests() {
  const dispatch = useDispatch();
  //   const reports = useSelector(state => state.Reports.reports);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Consultants Document Requests"
          subtitle="Collection of all requests "
          className="text-sm-left mb-3"
        />
      </Row>
      <Row>
        <div>
          <p>No request is available...</p>
        </div>
        {/* {reports.length > 0 ? (
          reports.map((item, index) => {
            return <ReportTicket data={item} index={index} />;
          })
        ) : reports.length === 0 ? (
          <p>No report is available yet...</p>
        ) : (
          <Spinner animation="border" />
        )} */}
      </Row>
    </Container>
  );
}

export default DocRequests;
