import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Tabs, Tab } from "react-bootstrap";
import CompanyInfoTab from "./CompanyInfoTab";
import EmployeeInfoTab from "./EmployeeInfoTab";
import MedicalPartnerInfoTab from "./MedicalPartnerInfoTab";
import ConsultantInfoTab from "./ConsultantInfoTab";
import SummaryTab from "./SummaryTab";
import { useSelector } from "react-redux";

const IndustryProfileDetails = ({ industryDetails }) => {
  const company = useSelector(state => state.Companies.company);
  return (
    <CardHeader small className="my-4">
      {/* <CardHeader className="border-bottom">
        <h6 className="m-0">Other Company Details</h6>
      </CardHeader> */}
      <Tabs defaultActiveKey="compInfo">
        <Tab
          eventKey="compInfo"
          title="Company Information"
          tabClassName="font-weight-bold p-2"
        >
          <CompanyInfoTab data={industryDetails} />
        </Tab>
        <Tab
          eventKey="empInfo"
          title="Employee Information"
          tabClassName="font-weight-bold p-2"
        >
          <EmployeeInfoTab data={industryDetails} />
        </Tab>
        <Tab
          eventKey="medPart"
          title="Medical Partners"
          tabClassName="font-weight-bold p-2"
        >
          <MedicalPartnerInfoTab data={industryDetails} />
        </Tab>
        <Tab
          eventKey="consultant"
          title="Consultants"
          tabClassName="font-weight-bold p-2 text-center"
        >
          <ConsultantInfoTab data={industryDetails} />
        </Tab>

        <Tab
          eventKey="summary"
          title="Summary"
          tabClassName="font-weight-bold p-2 text-center"
        >
          {<SummaryTab data={company} />}
        </Tab>
      </Tabs>
    </CardHeader>
  );
};

IndustryProfileDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

IndustryProfileDetails.defaultProps = {
  title: "Account Details"
};

export default IndustryProfileDetails;
