export const GET_ALL_ADMIN = "GET_ALL_ADMIN";

export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const GET_COMPANY = "GET_COMPANY";
export const GET_ALL_LOGS = "GET_ALL_LOGS";

export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCS = "GET_DOCS";

export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_SINGLE_PAYMENT = "GET_SINGLE_PAYMENT";

export const GET_REPORTS = "GET_REPORTS";

export const GET_ALL_CONSULTANTS = "GET_ALL_CONSULTANTS";
export const GET_CONSULTANT = "GET_CONSULTANT";

export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_MODULE = "GET_ROLES_MODULE";
