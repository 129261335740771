import React from "react";
import logo from "../../images/avatars/lasepa-logo.png";
import { Col } from "shards-react";
import moment from "moment";

function Invoice({ invoice, index }) {
  const formatMoney = currency => {
    const number = Number(currency) || 0;
    return number.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN"
    });
  };

  return (
    <Col lg="12" key={index}>
      <div className="p-4 m-1" style={{ backgroundColor: "#E5E9CE" }}>
        <div className="text-center">
          <img src={logo} style={{ maxWidth: "150px" }} />
          <h5 className="mt-2">{invoice.billName} Bill</h5>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <small className="p-0 m-0">Bill ID:</small>
            <h6 className="p-0 m-0">{invoice.billID}</h6>

            <small className="p-0 m-0">Company ID:</small>
            <h6 className="p-0 m-0">{invoice.companyID}</h6>
          </div>

          <div>
            <small className="p-0 m-0">Status:</small>
            <h6 className="p-0 m-0">
              {invoice.status == 0 ? "Unapproved" : "Approved"}
            </h6>

            {invoice.status == 1 ? (
              <>
                <small className="p-0 m-0">Approved by:</small>
                <h6>
                  {invoice.approvedby} on{" "}
                  {moment(invoice.approvedate * 1000).format("DD/MM/YYYY")}{" "}
                </h6>{" "}
              </>
            ) : null}
          </div>
        </div>
        <div className="text-center p-0 m-0">
          <div
            className="d-flex"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <span>To Pay: &nbsp; </span> <h4>{formatMoney(invoice.amount)}</h4>
          </div>

          <small className="p-0 m-0 text-muted">Being payment for: </small>
          <h6>{invoice.details}</h6>
          <small className="p-0 m-0 text-muted" style={{ fontSize: ".9rem" }}>
            Invoice Created by {invoice.creator} at{" "}
            {moment(invoice.transDate * 1000).format("dddd, MMMM Do YYYY")}
          </small>
        </div>
      </div>
    </Col>
  );
}

export default Invoice;
