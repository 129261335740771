import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink, useRouteMatch } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";
import { useLocation, useHistory } from "react-router";

const SubSidebarNavItem = ({ item, match, to, ...props }) => {
  const location = document.location;
  const loc = useLocation();
  //   const hist = useHistory();
  const hist = useRouteMatch();
  //   console.log("location", loc);
  console.log("location two", to);
  //   console.log("location three", match);

  return (
    <NavItem>
      <NavLink tag={RouteNavLink} to={`${item.to}`}>
        {item.htmlBefore && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
          />
        )}
        {item.title && <span>{item.title}</span>}
        {item.htmlAfter && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
          />
        )}
      </NavLink>
    </NavItem>
  );
};
SubSidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SubSidebarNavItem;
