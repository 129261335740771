import React from "react";
import axios from "axios";
// import { useDispatch } from "react-redux";
import { getSession } from "../store/Actions/AdminActions";
import store from "../store";

// const dispatch = useDispatch();
// export const baseUrl = "https://lspapp.touchandpay.me";
export const baseUrl = "https://lasepa.xyz";

const axiosInstance = axios.create({
  baseURL: baseUrl
});

axiosInstance.interceptors.request.use(async config => {
  let token = sessionStorage.getItem("token");
  if (token) {
    // try {
    //   await store.dispatch(getSession());
    // } catch (error) {
    //   console.log(error);
    // }

    config.headers = {
      Authorization: `bearer ${token}`,
      "Content-Type": "application/json"
    };
  }
  return config;
});

export default axiosInstance;
