import React, { useEffect } from "react";
import {
  Col,
  Row,
  Form,
  FormInput,
  Button,
  FormTextarea,
  FormSelect
} from "shards-react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  assignRoleAction,
  getAllRoles
} from "../../store/Actions/RolesActions";

function AssignRole({ open, handleClose, rowData }) {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const roles = useSelector(state => state.Roles.roles);
  const adminProfile = JSON.parse(sessionStorage.getItem("userProfile"));

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const handleAssignRole = async values => {
    setLoading(true);

    const reqObj = {
      data: {
        ...values,
        admin: rowData && rowData.username
      }
    };
    console.log("assign admin", reqObj);

    const response = await dispatch(assignRoleAction(reqObj));
    if (response == 1) {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <h5>Assign Role</h5>{" "}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Formik
              initialValues={{
                roleID: ""
              }}
              validationSchema={yup.object({
                roleID: yup.string().required("Role is required")
              })}
              onSubmit={values => {
                console.log("values", values);
                handleAssignRole(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form>
                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="name"> Name</label>
                      <FormSelect
                        id="roleid"
                        value={values.roleID}
                        onChange={handleChange("roleID")}
                        onBlur={handleBlur("roleID")}
                      >
                        <option value="">Select Role...</option>
                        {roles &&
                          roles.map((item, index) => (
                            <option value={item.id}>{item.roleName}</option>
                          ))}
                      </FormSelect>
                      <small className="text-danger">
                        {" "}
                        {touched.roleID && errors.roleID}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="my-3" style={{ textAlign: "right" }}>
                      <Button outline theme="info" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        disabled={loading}
                        className="mx-2 "
                        onClick={handleSubmit}
                      >
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                        &nbsp; Assign Role
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default AssignRole;
