import React from "react";
import { Container, Row, Col, Card, CardHeader } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import CompleteFormExample from "../../components/components-overview/CompleteFormExample";

function Demo() {
  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title=""
          subtitle="Legal & Adminstrative Framework"
          className="text-sm-left mb-3"
        />
      </Row>
      <Row>
        <Col lg="6" className="mb-4">
          <Card small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Form </h6>
            </CardHeader>
            <CompleteFormExample />
          </Card>
        </Col>
        <Col lg="6" className="mb-4">
          <Card small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Form </h6>
            </CardHeader>
            <CompleteFormExample />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Demo;
