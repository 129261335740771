import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  FormRadio
} from "shards-react";
import * as Yup from "yup";
import { Formik, FieldArray, getIn } from "formik";
import { FiPlusCircle, FiMinusCircle, FiDivide } from "react-icons/fi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const EnergyDetailsForm = ({ state }) => {
  return (
    <Card small className="">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Energy Details</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Formik
                initialValues={{
                  energyType: "",
                  machinesDetails: "",
                  fuelQty: [{ type: "", qty: "" }],
                  powerQty: [{ type: "", qty: "" }],
                  generatorInfo: [{ capacity: "", emission: "", noise: "" }],
                  rawMaterials: [{ material: "", qty: "" }],
                  products: [{ material: "", qty: "" }]
                }}
                validationSchema={Yup.object({
                  energyType: Yup.string(),
                  machinesDetails: Yup.string(),
                  fuelQty: Yup.array().of(
                    Yup.object().shape({
                      type: Yup.string(),
                      qty: Yup.string()
                    })
                  ),
                  powerQty: Yup.array().of(
                    Yup.object().shape({
                      type: Yup.string(),
                      qty: Yup.string()
                    })
                  ),
                  generatorInfo: Yup.array().of(
                    Yup.object().shape({
                      capacity: Yup.string(),
                      emission: Yup.string(),
                      noise: Yup.string()
                    })
                  ),
                  rawMaterials: Yup.array()
                    .of(
                      Yup.object().shape({
                        material: Yup.string().min(
                          2,
                          "Must be greater than 2 characters"
                        ),
                        qty: Yup.string()
                      })
                    )
                    .required("Must have at least one Raw Material"),
                  products: Yup.array()
                    .of(
                      Yup.object().shape({
                        material: Yup.string(),
                        qty: Yup.string()
                      })
                    )
                    .required("Must have at least one Raw Material")
                })}
                onSubmit={values => {
                  console.log("form submit", values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <Form>
                    <Row>
                      <Col md="4">
                        <label htmlFor="energyType">
                          A. &nbsp; Type of Energy
                        </label>
                      </Col>
                      <Col md="8" className="form-group">
                        <FormSelect
                          id="energyType"
                          onChange={handleChange("energyType")}
                          onBlur={handleBlur("energyType")}
                          value={values.energyType}
                        >
                          <option value="">Select Energy source</option>
                          <option value="gas">Gas</option>
                          <option value="diesel">Diesel</option>
                          <option value="PHCN">PHCN</option>
                          <option value="Solar">Solar</option>
                          <option value="Biogas">Biogas</option>
                          <option value="Gas">Gas(LNG or CNG)</option>
                          <option value="Hybrid">Hybrid</option>
                        </FormSelect>
                        <small className="text-danger">
                          {" "}
                          {touched.energyType && errors.energyType}{" "}
                        </small>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <label htmlFor="fuelQty">
                          B. &nbsp; Fuel Quantity / Month <br />{" "}
                          <small>
                            (Eg: Diesel, Petrol, Engine Oil, LPFO, GAS/LPG/CNG,
                            Bio/Natural, Other oil)
                          </small>
                        </label>
                      </Col>

                      <Col md="8" className="mt-3">
                        <FieldArray name="fuelQty">
                          {({ push, remove }) => (
                            <>
                              {values.fuelQty.map((item, index) => {
                                const typeName = `fuelQty[${index}].type`;
                                const qtyName = `fuelQty[${index}].qty`;
                                const typeError = getIn(errors, typeName);
                                const qtyError = getIn(errors, qtyName);
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <>
                                      <FormSelect
                                        id="type"
                                        name={typeName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={item.type}
                                      >
                                        <option value="">
                                          Select Fuel Type
                                        </option>
                                        <option value="Diesel">Diesel</option>
                                        <option value="Petrol">Petrol</option>
                                        <option value="Engine Oil">
                                          Engine Oil
                                        </option>
                                        <option value="LPFO">LPFO</option>
                                        <option value="GAS/LPG/CNG">
                                          GAS/LPG/CNG
                                        </option>
                                        <option value="Bio/Natural">
                                          Bio/Natural
                                        </option>
                                        <option value="Other oil">
                                          Other oil
                                        </option>
                                      </FormSelect>
                                      {typeError && (
                                        <small className="text-danger">
                                          {typeError}
                                        </small>
                                      )}

                                      <FormInput
                                        id="fuelQty"
                                        name={qtyName}
                                        placeholder="Quantity"
                                        value={item.qty}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2 mx-2"
                                      />

                                      {qtyError && (
                                        <small className="text-danger">
                                          {qtyError}
                                        </small>
                                      )}
                                    </>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                      <FiMinusCircle
                                        onClick={() => remove(index)}
                                        className=""
                                        size={"2rem"}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                );
                              })}

                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Add More</Tooltip>}
                              >
                                <FiPlusCircle
                                  onClick={() =>
                                    push({ material: "", qty: "" })
                                  }
                                  className="my-2"
                                  size={24}
                                  style={{ cursor: "pointer" }}
                                />
                              </OverlayTrigger>
                            </>
                          )}
                        </FieldArray>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <label htmlFor="powerQty">
                          C. &nbsp; Maximum Power Consumption / Day <br />{" "}
                          <small>
                            (Eg: PHCN, Generator, Gas Energy, others )
                          </small>
                        </label>
                      </Col>

                      <Col md="8" className="mt-3">
                        <FieldArray name="powerQty">
                          {({ push, remove }) => (
                            <>
                              {values.powerQty.map((item, index) => {
                                const typeName = `powerQty[${index}].type`;
                                const qtyName = `powerQty[${index}].qty`;
                                const typeError = getIn(errors, typeName);
                                const qtyError = getIn(errors, qtyName);
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <>
                                      <FormSelect
                                        id="type"
                                        name={typeName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={item.type}
                                      >
                                        <option value="">
                                          Select Power Source
                                        </option>
                                        <option value="PHCN">PHCN</option>
                                        <option value="Generator">
                                          Generator
                                        </option>
                                        <option value="Gas Energy">
                                          Gas Energy
                                        </option>
                                        <option value="Others">Others</option>
                                      </FormSelect>
                                      {typeError && (
                                        <small className="text-danger">
                                          {typeError}
                                        </small>
                                      )}

                                      <FormInput
                                        id="powerQty"
                                        name={qtyName}
                                        placeholder="Quantity"
                                        value={item.qty}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2 mx-2"
                                      />

                                      {qtyError && (
                                        <small className="text-danger">
                                          {qtyError}
                                        </small>
                                      )}
                                    </>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                      <FiMinusCircle
                                        onClick={() => remove(index)}
                                        className=""
                                        size={"2rem"}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                );
                              })}

                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Add More</Tooltip>}
                              >
                                <FiPlusCircle
                                  onClick={() =>
                                    push({ material: "", qty: "" })
                                  }
                                  className="my-2"
                                  size={24}
                                  style={{ cursor: "pointer" }}
                                />
                              </OverlayTrigger>
                            </>
                          )}
                        </FieldArray>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <label htmlFor="generatorInfo">
                          D. &nbsp; Generator Information
                        </label>
                      </Col>

                      <Col md="8" className="mt-3">
                        <FieldArray name="generatorInfo">
                          {({ push, remove }) => (
                            <>
                              {values.generatorInfo.map((item, index) => {
                                const capacityName = `generatorInfo[${index}].capacity`;
                                const emissionName = `generatorInfo[${index}].emission`;
                                const noiseName = `generatorInfo[${index}].noise`;
                                const capacityError = getIn(
                                  errors,
                                  capacityName
                                );
                                const emissionError = getIn(
                                  errors,
                                  emissionName
                                );
                                const noiseError = getIn(errors, noiseName);

                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <>
                                      <FormInput
                                        id="generatorInfo"
                                        name={capacityName}
                                        placeholder="Capacity"
                                        value={item.capacity}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2 mx-2"
                                      />
                                      {capacityError && (
                                        <small className="text-danger">
                                          {capacityError}
                                        </small>
                                      )}

                                      <FormInput
                                        id="generatorInfo"
                                        name={emissionName}
                                        placeholder="Emission"
                                        value={item.emission}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2 mx-2"
                                      />

                                      {emissionError && (
                                        <small className="text-danger">
                                          {emissionError}
                                        </small>
                                      )}

                                      <FormInput
                                        id="generatorInfo"
                                        name={noiseName}
                                        placeholder="Noise"
                                        value={item.noise}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2 mx-2"
                                      />

                                      {noiseError && (
                                        <small className="text-danger">
                                          {noiseError}
                                        </small>
                                      )}
                                    </>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                      <FiMinusCircle
                                        onClick={() => remove(index)}
                                        className=""
                                        size={"2rem"}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                );
                              })}

                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Add More</Tooltip>}
                              >
                                <FiPlusCircle
                                  onClick={() =>
                                    push({
                                      capacity: "",
                                      emission: "",
                                      noise: ""
                                    })
                                  }
                                  className="my-2"
                                  size={24}
                                  style={{ cursor: "pointer" }}
                                />
                              </OverlayTrigger>
                            </>
                          )}
                        </FieldArray>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col className="text-right">
                        <Button theme="accent" onClick={handleSubmit}>
                          Save
                        </Button>
                        <Button
                          outline
                          className="my-3 mx-2"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Preview Details
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};
EnergyDetailsForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

EnergyDetailsForm.defaultProps = {
  title: "Account Details"
};

export default EnergyDetailsForm;
