import React, { useEffect, useState } from "react";
import { Container, Row, Card, Col, Button } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaymentsInvoice,
  getAllPaymentsInvoice
} from "../../store/Actions/PaymentsActions";
import InvoiceDataTable from "./InvoiceDataTable";
import CreateInvoice from "./CreateInvoice";
import { Spinner } from "react-bootstrap";
// import InvoiceList from "./InvoiceList";

function Payments() {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const payments = useSelector(state => state.Payments.payments);

  const toggleCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleCloseModal = () => {
    setOpenCreateModal(val => !val);
  };

  useEffect(() => {
    async function callGetPayments() {
      const response = await dispatch(getAllPaymentsInvoice());
    }

    callGetPayments();
  }, []);

  console.log("payments", payments);

  return (
    <Container fluid className="main-content-container px-4">
      <CreateInvoice open={openCreateModal} handleClose={handleCloseModal} />
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Invoice "
          subtitle="Create and View all company invoice"
          className="text-sm-left mb-2"
        />
      </Row>
      <Row>
        <Col>
          <div></div>
          <Button onClick={toggleCreateModal}>Create Invoice</Button>
        </Col>
      </Row>

      <Row className="my-2">
        <Col lg="12">
          {payments.length >= 0 ? (
            <InvoiceDataTable payments={payments} />
          ) : (
            <Spinner animation="border" />
          )}
        </Col>
        {/* <Col>{payments && <InvoiceList payments={payments} />}</Col> */}
      </Row>
    </Container>
  );
}

export default Payments;
