import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormCheckbox,
  FormSelect
} from "shards-react";
import * as Yup from "yup";
import { Formik, FieldArray, getIn } from "formik";
import { FiPlusCircle, FiMinusCircle, FiDivide } from "react-icons/fi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PollutionManagementForm = ({ state }) => {
  return (
    <Card small className="">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Air and Noise Pollution Details</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Formik
                initialValues={{
                  pollutionArea: [
                    {
                      prodHall: false,
                      packagingArea: false,
                      genHouse: false,
                      facilityEntrance: false,
                      adminOff: false,
                      others: false
                    }
                  ],
                  pollutionIndicators: ""
                }}
                validationSchema={Yup.object({
                  pollutionArea: Yup.array().of(
                    Yup.object().shape({
                      prodHall: Yup.boolean(),
                      packagingArea: Yup.boolean(),
                      genHouse: Yup.boolean(),
                      facilityEntrance: Yup.boolean(),
                      adminOff: Yup.boolean(),
                      others: Yup.boolean()
                    })
                  ),
                  pollutionIndicators: Yup.string()
                })}
                onSubmit={values => {
                  console.log("form submit", values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <Form>
                    <Row>
                      <Col md="4">
                        <label htmlFor="pollutionArea">
                          Select noise and air pollution in the following areas
                        </label>
                      </Col>
                      <Col md="8">
                        <FieldArray name="pollutionArea">
                          <>
                            {values.pollutionArea.map((item, index) => {
                              return (
                                <div key={index}>
                                  <Row>
                                    <Col lg="4">
                                      <FormCheckbox
                                        checked={item.prodHall}
                                        onChange={e => {
                                          if (e.target.checked) {
                                            item.prodHall = !item.prodHall;
                                          }
                                          console.log(values.index);
                                        }}
                                      >
                                        Production Hall
                                      </FormCheckbox>
                                    </Col>

                                    <Col lg="4">
                                      <FormCheckbox>
                                        Packaging Area
                                      </FormCheckbox>
                                    </Col>

                                    <Col lg="4">
                                      <FormCheckbox>
                                        Generator House
                                      </FormCheckbox>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg="4">
                                      <FormCheckbox>
                                        Facility Entrance
                                      </FormCheckbox>
                                    </Col>

                                    <Col lg="4">
                                      <FormCheckbox>
                                        Adminstrative Office (Control)
                                      </FormCheckbox>
                                    </Col>

                                    <Col lg="4">
                                      <FormCheckbox>Others</FormCheckbox>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          </>
                        </FieldArray>
                      </Col>
                    </Row>
                    <hr />

                    <Row>
                      <Col md="4" className="form-group">
                        <label htmlFor="pollutionIndicators">
                          Important Air Pollution Indicators
                        </label>
                      </Col>
                      <Col md="8">
                        <FormSelect
                          id="pollutionIndicators"
                          onChange={handleChange("pollutionIndicators")}
                          onBlur={handleBlur("pollutionIndicators")}
                          value={values.pollutionIndicators}
                        >
                          <option value="">Select Indicator</option>
                          <option value="partMatter">
                            Particulate Matter (5PM)
                          </option>
                          <option value="h25">H25</option>
                          <option value="so2">S02</option>
                          <option value="ch4">CH4</option>
                          <option value="co">CO</option>
                        </FormSelect>
                        <small className="text-danger">
                          {" "}
                          {touched.pollutionIndicators &&
                            errors.pollutionIndicators}{" "}
                        </small>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12" className="text-right mt-3">
                        <Button
                          className="my-3"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                        <Button
                          outline
                          className="my-3 mx-2"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Preview Details
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};
PollutionManagementForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

PollutionManagementForm.defaultProps = {
  title: "Account Details"
};

export default PollutionManagementForm;
