import { _signInRequest } from "../../api/AuthRequest";
import { NotificationManager } from "react-notifications";

export const authActions = () => {
  return dispatch => {
    console.log("dispatchjsddjsd");
    dispatch({ type: "AUTH", payload: true });
  };
};

export const signInAction = obj => {
  return async dispatch => {
    try {
      const reqObj = {
        data: {
          username: obj.username,
          password: obj.password
        }
      };
      const { data } = await _signInRequest(reqObj);
      console.log("login successfully", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        setAuthorization(data.content);
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("signin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

const setAuthorization = content => {
  if (content) {
    console.log("authorizatoon", content.data[0]);
    sessionStorage.setItem("token", content.token);
    sessionStorage.setItem("roles", JSON.stringify(content.roles));
    sessionStorage.setItem("userProfile", JSON.stringify(content.data[0]));
  }
};

export const logoutUser = () => {
  return dispatch => {
    sessionStorage.clear();
    localStorage.clear();
    NotificationManager.success("Logout successful", "Success");
  };
};
