import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormRadio,
  FormGroup
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import CompleteFormExample from "../../../components/components-overview/CompleteFormExample";
import LegalForm from "./LegalForm";

function Legal() {
  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title=""
          subtitle="Legal & Adminstrative Framework"
          className="text-sm-left mb-3"
        />
      </Row>
      {/* <Row>
        <Col lg="6" className="mb-4">
          <Card small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Form </h6>
            </CardHeader>
            <CompleteFormExample />
          </Card>
        </Col>
        <Col lg="6" className="mb-4">
          <Card small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Form </h6>
            </CardHeader>
            <CompleteFormExample />
          </Card>
        </Col>
      </Row> */}

      <Row>
        <Col lg="12">
          <LegalForm  />
        </Col>
      </Row>
    </Container>
  );
}

export default Legal;
