import { GET_ROLES, GET_ROLES_MODULE } from "../types";
import { NotificationManager } from "react-notifications";
import {
  _assignRole,
  _createRole,
  _getRole,
  _updateRole,
  _getRoleModules,
  _assignModule
} from "../../api/RolesRequest";

export const getAllRoles = () => {
  return async dispatch => {
    try {
      const { data } = await _getRole();
      console.log("roles  ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_ROLES, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const createRoleAction = obj => {
  return async dispatch => {
    try {
      const { name, description, roleID } = obj;

      const reqObj = {
        data: {
          name,
          description,
          roleID
        }
      };
      const { data } = await _createRole(reqObj);
      console.log("success created ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch(getAllRoles());
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const updateRoleAction = reqObj => {
  return async dispatch => {
    try {
      const { data } = await _updateRole(reqObj);
      console.log("success created ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch(getAllRoles());
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const assignRoleAction = reqObj => {
  return async dispatch => {
    try {
      const { data } = await _assignRole(reqObj);
      console.log("success created ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch(getAllRoles());
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const getRoleModules = id => {
  return async dispatch => {
    try {
      const { data } = await _getRoleModules(id);
      console.log("roles id ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_ROLES_MODULE, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const assignModulesAction = reqObj => {
  return async dispatch => {
    try {
      const { data } = await _assignModule(reqObj);
      console.log("success created ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch(getAllRoles());
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};
