import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  // Modal,
  // ModalHeader,
  // ModalBody,
  Form,
  FormInput,
  Button,
  FormTextarea,
  FormSelect
  // ModalFooter
} from "shards-react";
import { useDispatch } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { createInvoiceAction } from "../../store/Actions/PaymentsActions";

function CreateInvoice({ open, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [companyVal, setCompanyVal] = useState("");
  const [toggleDisplay, setToggleDisplay] = useState("none");
  const dispatch = useDispatch();

  const handleSubmit = async val => {
    setLoading(true);
    const response = await dispatch(createInvoiceAction(val));
    if (response == 1) {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  };

  //   for toggle companyiD. check again
  //   useEffect(() => {
  //     setToggleDisplay("block");
  //     validation(companyVal);
  //   }, [companyVal]);

  //   const validation = companyVal => {
  //     console.log("com ", companyVal);
  //     if (companyVal == 0) {
  //       return yup.string().required("Company ID is required");
  //     } else return yup.string();
  //   };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        {" "}
        <h4>Create Invoice</h4>{" "}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Formik
              initialValues={{
                billName: "",
                amount: "",
                details: "",
                toBill: "",
                companyID: ""
              }}
              validationSchema={yup.object({
                billName: yup.string().required("Bill name is required"),
                amount: yup.string().required("Amount is required"),
                details: yup.string().required("Details is required"),
                toBill: yup.string().required("Who to Bill is required"),
                companyID: yup.string()
              })}
              onSubmit={values => {
                handleSubmit(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form>
                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="billName">Bill Name</label>
                      <FormInput
                        id="billName"
                        type="text"
                        placeholder="Bill name"
                        value={values.billName}
                        onChange={handleChange("billName")}
                        onBlur={handleBlur("billName")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.billName && errors.billName}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <label htmlFor="amount">Amount</label>
                      <FormInput
                        id="amount"
                        type="text"
                        placeholder="Amount"
                        value={values.amount}
                        onChange={handleChange("amount")}
                        onBlur={handleBlur("amount")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.amount && errors.amount}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="details">Details</label>

                      <FormTextarea
                        id="details"
                        rows="3"
                        value={values.details}
                        onChange={handleChange("details")}
                        onBlur={handleBlur("details")}
                        placeholder="Bill description"
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.details && errors.details}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <label htmlFor="toBill">Who to Bill</label>

                      <FormSelect
                        id="toBill"
                        value={values.toBill}
                        // onChange={handleChange("toBill")}
                        onChange={e => {
                          setFieldValue("toBill", e.target.value);
                          if (e.target.value == 0) {
                            setCompanyVal(e.target.value);
                          }
                        }}
                        onBlur={handleBlur("toBill")}
                      >
                        <option value="">Select Who to Bill...</option>
                        <option value="0">Specific Company</option>
                        <option value="1">All companies</option>
                        <option value="2">All consultants</option>
                      </FormSelect>
                      <small className="text-danger">
                        {" "}
                        {touched.toBill && errors.toBill}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row className={`mb-2 ${toggleDisplay}`}>
                    <Col lg="12">
                      <label htmlFor="companyID">Company ID</label>
                      <FormInput
                        id="companyID"
                        type="text"
                        placeholder="Company ID"
                        value={values.companyID}
                        onChange={handleChange("companyID")}
                        onBlur={handleBlur("companyID")}
                        // style={{ display: `${toggleDisplay}` }}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.companyID && errors.companyID}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ textAlign: "right" }}>
                      <Button outline theme="info" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        className="mx-2 my-2"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default CreateInvoice;
