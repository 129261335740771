import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompany,
  updateCompanyBasicInfoAction
} from "../../store/Actions/CompanyActions";

function CompanyInfoTab({ data }) {
  const [companyID, setCompanyID] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const company = useSelector(state => state.Companies.company);
  console.log("company single", company);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setCompanyID(data.companyID);
      async function getSingleCompany() {
        await dispatch(getCompany(companyID));
      }
      getSingleCompany();
    }
  }, [companyID]);

  //   useEffect(() => {
  //     dispatch(getCompany(companyID));
  //   }, []);

  const handleUpdateCompanyInfo = async values => {
    setLoading(true);
    const obj = {
      data: {
        ...values
      }
    };
    console.log("co", obj);
    const response = await dispatch(updateCompanyBasicInfoAction(obj));
    if (response) {
      setLoading(false);
    }
  };
  return (
    <div className="p-4">
      <Row>
        <Col>
          <Formik
            initialValues={{
              name: `${data && data.name}`,
              opprocess: "",
              area: "",
              areaUsed: "",
              yearofinc: `${company && company.yearofinc}`,
              yearofop: "",
              coord: "",
              envpolicy: "",
              dateofcapture: "",
              techreport: "",
              companyID: `${data && data.companyID}`
            }}
            validationSchema={Yup.object({
              opprocess: Yup.string(),
              area: Yup.string(),
              areaUsed: Yup.string(),
              yearofinc: Yup.string(),
              yearofop: Yup.string(),
              coord: Yup.string(),
              envpolicy: Yup.string(),
              dateofcapture: Yup.string(),
              techreport: Yup.string()
            })}
            onSubmit={values => {
              console.log("form submit", values);
              handleUpdateCompanyInfo(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors
            }) => (
              <Form>
                <Row>
                  <Col lg="12" className="form-group">
                    <label htmlFor="name">Company's Name</label>
                    <FormInput
                      id="name"
                      placeholder="Enter Company's Name"
                      value={values.name}
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.name && errors.name}{" "}
                    </small>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg="6">
                    <label htmlFor="yearofinc">Date of Incorporation</label>
                    <FormInput
                      id="yearofinc"
                      type="date"
                      value={values.yearofinc}
                      onChange={handleChange("yearofinc")}
                      onBlur={handleBlur("yearofinc")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.yearofinc && errors.yearofinc}{" "}
                    </small>
                  </Col>

                  <Col lg="6">
                    <label htmlFor="yearofop">Date of Operation</label>
                    <FormInput
                      id="yearofop"
                      type="date"
                      value={values.yearofop}
                      onChange={handleChange("yearofop")}
                      onBlur={handleBlur("yearofop")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.yearofop && errors.yearofop}{" "}
                    </small>
                  </Col>
                </Row>
                <Row form>
                  {/* Operational Process */}
                  <Col md="6" className="form-group">
                    <label htmlFor="opprocess">Operational Process</label>
                    <FormSelect
                      id="opprocess"
                      onChange={handleChange("opprocess")}
                      onBlur={handleBlur("opprocess")}
                      value={values.opprocess}
                    >
                      <option value="">Select Operational Process</option>
                      <option value="wet">Wet</option>
                      <option value="dry">Dry</option>
                    </FormSelect>
                    <small className="text-danger">
                      {" "}
                      {touched.opprocess && errors.opprocess}{" "}
                    </small>
                  </Col>
                  {/* Company Size */}
                  <Col md="3" className="form-group">
                    <label htmlFor="area">
                      Company Size(m<sup>2</sup>)
                    </label>
                    <FormInput
                      id="area"
                      placeholder=""
                      value={values.area}
                      onChange={handleChange("area")}
                      onBlur={handleBlur("area")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.area && errors.area}{" "}
                    </small>
                  </Col>
                  {/* Land use area */}
                  <Col md="3" className="form-group">
                    <label htmlFor="areaUsed">
                      Land Use Area(m<sup>2</sup>)
                    </label>
                    <FormInput
                      id="areaUsed"
                      placeholder=""
                      value={values.areaUsed}
                      onChange={handleChange("areaUsed")}
                      onBlur={handleBlur("areaUsed")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.areaUsed && errors.areaUsed}{" "}
                    </small>
                  </Col>
                </Row>

                <Row form>
                  {/* Company's Email */}
                  <Col md="6" className="form-group">
                    <label htmlFor="coord">
                      GPS at Middle of Entrance Gate
                    </label>
                    <FormInput
                      id="coord"
                      placeholder="Coordinates"
                      value={values.coord}
                      onChange={handleChange("coord")}
                      onBlur={handleBlur("coord")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.coord && errors.coord}{" "}
                    </small>
                  </Col>
                  <Col md="6" className="form-group">
                    <label htmlFor="envpolicy">Environmental Policy</label>
                    <FormSelect
                      id="envpolicy"
                      value={values.envpolicy}
                      onChange={handleChange("envpolicy")}
                      onBlur={handleBlur("envpolicy")}
                    >
                      <option value="">Select Environmental Policy</option>
                      <option value="available">Available</option>
                      <option value="available and signed">
                        Available and Signed
                      </option>
                      <option value="not available">Not Available</option>
                    </FormSelect>
                    <small className="text-danger">
                      {" "}
                      {touched.envpolicy && errors.envpolicy}{" "}
                    </small>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <label htmlFor="techreport">
                      Technical Report (EIA/EAR/EMP)
                    </label>
                  </Col>
                  <Col lg="6">
                    <label htmlFor="dateofcapture">Date of Capture</label>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col md="6">
                    <input
                      type="file"
                      className="custom-file-input mb-3"
                      id="techreport"
                      value={values.techreport}
                      onChange={handleChange("techreport")}
                      onBlur={handleBlur("techreport")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.techreport && errors.techreport}{" "}
                    </small>

                    <label
                      className="custom-file-label mx-3"
                      htmlFor="customFile2"
                      id="techReport"
                    >
                      Upload Technical Report...
                    </label>
                  </Col>

                  <Col lg="6">
                    {/* <label htmlFor="dateofcapture">Date of Capture</label> */}
                    <FormInput
                      id="dateofcapture"
                      type="date"
                      value={values.dateofcapture}
                      onChange={handleChange("dateofcapture")}
                      onBlur={handleBlur("dateofcapture")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.dateofcapture && errors.dateofcapture}{" "}
                    </small>
                  </Col>
                </Row>

                <Button
                  theme="accent"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading && (
                    <Spinner animation="border" variant="secondary" size="sm" />
                  )}
                  &nbsp; Update Company Profile
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default CompanyInfoTab;
