import axios from "./index";

// document manager api requests
export const _getDocuments = companyID => {
  return axios.get(`/v1/admin/get/document/${companyID}`);
};

export const _createDocument = reqObj => {
  return axios.post("/v1/admin/add/document", reqObj);
};

export const _updateDocument = reqObj => {
  return axios.post("/v1/admin/add/document", reqObj);
};

export const _uploadFileToDocument = reqObj => {
  return axios.put("/v1/admin/add/filetodocument", reqObj);
};

export const _deleteDocument = reqObj => {
  return axios.delete("/v1/admin/delete/document", { data: reqObj });
};

export const _deleteFile = reqObj => {
  return axios.delete("/v1/admin/delete/doc", { data: reqObj });
};

export const _getDocs = reqObj => {
  return axios.get(`/v1/admin/get/doc/${reqObj.companyID}/${reqObj.refID}/-`);
};
