export default function() {
  return [
    {
      title: "Company Manager",
      to: "",
      htmlBefore: '<i class="material-icons">view_module</i>',
      htmlAfter: ""
    },
    {
      title: "Consultants",
      to: "/consultants",
      htmlBefore: '<i class="material-icons">view_module</i>',
      htmlAfter: ""
    },
    {
      title: "Complaints Report",
      to: "/reports",
      htmlBefore: '<i class="material-icons">person</i>',
      htmlAfter: ""
    },
    {
      title: "Document Requests",
      to: "/doc-requests",
      htmlBefore: '<i class="material-icons">person</i>',
      htmlAfter: ""
    },
    {
      title: "Payments",
      to: "/payments",
      htmlBefore: '<i class="material-icons">person</i>',
      htmlAfter: ""
    },
    {
      title: "Activities Log",
      to: "/activities-log",
      htmlBefore: '<i class="material-icons">person</i>',
      htmlAfter: ""
    },
    {
      title: "Admin",
      to: "/admin",
      htmlBefore: '<i class="material-icons">person</i>',
      htmlAfter: ""
    },
    {
      title: "Access Level",
      to: "/access-level",
      htmlBefore: '<i class="material-icons">person</i>',
      htmlAfter: ""
    }
    // {
    //   title: "Legal & Admin",
    //   to: "/industry-manager/details/:id/legal",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   htmlAfter: "",
    //   submenu: true
    // },
    // {
    //   title: "Add New Post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post"
    // },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview"
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables"
    // },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite"
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors"
    // }
  ];
}
