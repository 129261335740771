import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Col,
  Button
} from "shards-react";
import { Spinner } from "react-bootstrap";
import PageTitle from "../../components/common/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getAllConsultants } from "../../store/Actions/ConsultantActions";
import AddConsultants from "./AddConsultants";
import ConsultantsManagerList from "./ConsultantsManagerList";

function ConsultantManager({ match }) {
  const [loading, setLoading] = useState(false);
  const [openAddConsultants, setOpenAddConsultants] = useState(false);
  const dispatch = useDispatch();
  const allConsultants = useSelector(state => state.Consultants.allConsultants);

  useEffect(() => {
    async function callGetConsultants() {
      await dispatch(getAllConsultants());
    }

    callGetConsultants();
  }, []);

  const toggleOpenAddConsultants = () => {
    setOpenAddConsultants(true);
  };

  const handleClose = () => {
    console.log("hello");
    setOpenAddConsultants(val => !val);
  };

  return (
    <Container fluid className="main-content-container px-4">
      <AddConsultants open={openAddConsultants} handleClose={handleClose} />
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Consultant Manager"
          subtitle="Consultant activities on the platform"
          className="text-sm-left mb-2"
        />
      </Row>

      <Row className="d-flex justify-content-end mr-1 my-2">
        <Button
          theme="primary"
          className="mr-1  d-flex align-items-center"
          onClick={toggleOpenAddConsultants}
        >
          <i
            className="material-icons text-size-2"
            style={{ fontSize: "1.2rem" }}
          >
            add
          </i>
          Add Consultants
        </Button>
      </Row>

      <Row>
        {!loading ? (
          <ConsultantsManagerList data={allConsultants} />
        ) : (
          <Spinner animation="border" />
        )}
      </Row>
    </Container>
  );
}

export default ConsultantManager;
