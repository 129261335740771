import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  Button
} from "shards-react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  getDocuments,
  updateDocumentAction
} from "../../../store/Actions/DocumentsActions";
import NewDocCategoryModal from "./NewDocCategoryModal";
import UploadFileToDoc from "./UploadFileToDoc";
import { MdDelete } from "react-icons/md";
import Document from "./Document";
import { useParams } from "react-router-dom";

const Documents = ({ state }) => {
  const [compInfo, setCompInfo] = useState(null);
  const [openNewDocModal, setOpenNewDocModal] = useState(false);
  const dispatch = useDispatch();
  const documents = useSelector(state => state.Documents.documents);
  const param = useParams();
  console.log("this params", documents);

  const toggleNewDocModal = () => {
    setOpenNewDocModal(true);
  };

  const closeNewDocModal = () => {
    setOpenNewDocModal(val => !val);
  };

  useEffect(() => {
    try {
      const companyInfo = JSON.parse(localStorage.getItem("activeCompany"));
      const { companyID } = companyInfo;
      setCompInfo(companyInfo);
      if (companyID) {
        dispatch(getDocuments(companyID));
      } else {
        console.log("reached");
        dispatch(getDocuments(param.id));
      }
    } catch (error) {
      console.log("documents error", error);
    }
  }, []);

  return (
    <div className="">
      <NewDocCategoryModal
        open={openNewDocModal}
        handleClose={closeNewDocModal}
      />

      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Row>
                <Col md="12" className="text-right ">
                  <Button onClick={toggleNewDocModal} theme="accent">
                    Create New Category
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              {documents.length > 0 ? (
                documents.map((item, index) => {
                  return (
                    <Card key={index} className="my-2 p-4">
                      <Document index={index} item={item} compInfo={compInfo} />
                    </Card>
                  );
                })
              ) : (
                <p>No documents available... Please create a new document</p>
              )}
              {/* <Card></Card> */}
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default Documents;
