import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "shards-react";
import MaterialTable from "material-table";
import moment from "moment";
import AssignRole from "./AssignRole";

const AdminStaff = ({ admin }) => {
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [rowVal, setRowVal] = useState(null);
  const userProf = JSON.parse(sessionStorage.getItem("userProfile"));
  const toggleAssignModal = rowData => {
    setOpenAssignModal(true);
    setRowVal(rowData);
  };

  const handleClose = () => {
    setOpenAssignModal(val => !val);
  };
  return (
    <Card small className="my-2">
      {/* <CardHeader className="border-bottom">
      <h6 className="m-0">Staff Overview</h6>
    </CardHeader> */}

      <CardBody>
        <AssignRole
          open={openAssignModal}
          handleClose={handleClose}
          rowData={rowVal}
        />
        <MaterialTable
          title="Staffs"
          columns={[
            { title: "StaffID", field: "staffID" },
            { title: "Fullname", field: "fullname" },
            { title: "Username", field: "username" },
            { title: "Email", field: "email" },
            { title: "Phone", field: "phone" },
            { title: "Address", field: "address" },
            {
              title: "Date",
              field: "dateCreated",
              render: rowData =>
                moment(rowData.dateCreated * 1000).format("DD-MM-YYYY")
            },
            {
              title: "Access Type",
              field: "usertype",
              render: rowData =>
                rowData.usertype == 0 ? "Super Admin" : "Staff"
            },
            {
              title: "Actions",
              field: "",
              render: rowData =>
                userProf.usertype == 0 ? (
                  <span
                    className="btn btn-primary"
                    onClick={() => toggleAssignModal(rowData)}
                  >
                    Assign Role
                  </span>
                ) : null
            }
          ]}
          data={admin}
          options={{
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF"
            },
            actionsColumnIndex: -1
          }}
          // actions={[
          //   {
          //     icon: () => <Button>Assign Role</Button>,
          //     tooltip: "Assign Role",
          //     onClick: (event, rowData) => {
          //       toggleAssignModal(rowData);
          //     }
          //   }
          // ]}
        />
      </CardBody>
    </Card>
  );
};

export default AdminStaff;
