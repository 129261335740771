import React from "react";
import { Container, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";

function ReportNoise() {
  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Report Noise"
          subtitle="Report noise pollution"
          className="text-sm-left mb-3"
        />
      </Row>
      <Row>
        <h1>hello noise</h1>
      </Row>
    </Container>
  );
}

export default ReportNoise;
