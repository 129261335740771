export default function(id) {
  // const { id } = JSON.parse(localStorage.getItem("activeID"));
  // let state, id;
  // try {
  //   id = JSON.parse(localStorage.getItem("activeID"));
  //   // id = state.id;
  // } catch (error) {
  //   // console.log(error.message);
  // }
  // console.log("this sidebar id ", id);

  return [
    {
      title: "Files Manager",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/attachements`,
      submenu: true
    },
    {
      title: "Production Details",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/prod-details`,
      submenu: true
    },
    {
      title: "Warehouse Details",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/warehouse-details`,
      submenu: true
    },
    {
      title: "Energy Details",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/energy-details`,
      submenu: true
    },
    {
      title: "Carbon Emissions",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/carbon-emissions`,
      submenu: true
    },
    {
      title: "Storage Tank",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/storage-tank`,
      submenu: true
    },
    {
      title: "Water Information",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/water-details`,
      submenu: true
    },
    {
      title: "Pollution Management",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/pollution-mgmt`,
      submenu: true
    },
    {
      title: "BakeHouse Details",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/bakehouse`,
      submenu: true
    },
    {
      title: "Waste Management",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/waste-mgmt`,
      submenu: true
    },
    {
      title: "Technical Details",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/other-tech`,
      submenu: true
    },
    {
      title: "Compliance Details",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/other-compliance`,
      submenu: true
    },
    {
      title: "Typographical Details",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: `/company-manager/details/${id}/typographical-details`,
      submenu: true
    }
  ];
}
