import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  FormInput,
  Button,
  FormTextarea,
  FormSelect
  // ModalFooter
} from "shards-react";
import { useDispatch } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { updateInvoiceAction } from "../../store/Actions/PaymentsActions";

function UpdateStatus({ open, handleClose, rowStatusData }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async val => {
    setLoading(true);
    const response = await dispatch(updateInvoiceAction(val));
    if (response == 1) {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        {" "}
        <h4>Update Status</h4>{" "}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Formik
              initialValues={{
                billID: `${rowStatusData && rowStatusData.billID}`,
                status: ""
              }}
              validationSchema={yup.object({
                status: yup.string().required("Please select a status")
              })}
              onSubmit={values => {
                handleSubmit(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form>
                  <Row>
                    <Col lg="12">
                      <label htmlFor="status">Status</label>

                      <FormSelect
                        id="status"
                        value={values.status}
                        // onChange={handleChange("status")}
                        onChange={e => {
                          setFieldValue("status", e.target.value);
                        }}
                        onBlur={handleBlur("status")}
                      >
                        <option value="">Select Status...</option>
                        <option value="1">Approve</option>
                        <option value="-1">Disapprove</option>
                      </FormSelect>
                      <small className="text-danger">
                        {" "}
                        {touched.status && errors.status}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ textAlign: "right" }}>
                      <Button outline theme="info" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        className="mx-2 my-2"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default UpdateStatus;
