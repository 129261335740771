import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { getAllReports } from "../../store/Actions/ReportActions";
import ReportTicket from "./ReportTicket";

function Reports() {
  const dispatch = useDispatch();
  const reports = useSelector(state => state.Reports.reports);
  console.log("reports", reports);
  reports.sort((a, b) => a - b);

  useEffect(() => {
    async function callGetReports() {
      await dispatch(getAllReports());
    }
    callGetReports();
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Reports"
          subtitle="Collection of all reports"
          className="text-sm-left mb-3"
        />
      </Row>
      <Row>
        {reports.length > 0 ? (
          reports.map((item, index) => {
            return <ReportTicket data={item} index={index} />;
          })
        ) : reports.length === 0 ? (
          <p>No report is available yet...</p>
        ) : (
          <Spinner animation="border" />
        )}
      </Row>
    </Container>
  );
}

export default Reports;
