import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  Button
} from "shards-react";
import { useDispatch, useSelector } from "react-redux";
import ConsultantNewDocCategory from "./ConsultantNewDocCategory";
import { getDocuments } from "../../store/Actions/DocumentsActions";
// import NewDocCategoryModal from "./NewDocCategoryModal";
import { useParams } from "react-router-dom";
import FileManagerDoc from "./FileManagerDoc";

const FileManagerDocs = ({ state }) => {
  // const [compInfo, setCompInfo] = useState(null);
  const [openNewDocModal, setOpenNewDocModal] = useState(false);
  const dispatch = useDispatch();
  const documents = useSelector(state => state.Documents.documents);
  const { id } = useParams();
  console.log("this documents reached", documents);

  const toggleNewDocModal = () => {
    setOpenNewDocModal(true);
  };

  const closeNewDocModal = () => {
    setOpenNewDocModal(val => !val);
  };

  useEffect(() => {
    try {
      // const companyInfo = JSON.parse(localStorage.getItem("activeCompany"));
      // const { companyID } = companyInfo;
      // setCompInfo(companyInfo);

      dispatch(getDocuments(id));
    } catch (error) {
      console.log("documents error", error);
    }
  }, []);

  return (
    <div className="">
      {/* <NewDocCategoryModal
        open={openNewDocModal}
        handleClose={closeNewDocModal}
      /> */}

      <ConsultantNewDocCategory
        open={openNewDocModal}
        handleClose={closeNewDocModal}
      />

      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Row>
                <Col md="12" className="text-right ">
                  <Button onClick={toggleNewDocModal} theme="accent">
                    Create New Category
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              {documents.length > 0 ? (
                documents.map((item, index) => {
                  return (
                    <div key={index} className="my-2 ">
                      <FileManagerDoc
                        index={index}
                        item={item}
                        compInfo={documents}
                      />
                    </div>
                  );
                })
              ) : (
                <p>No documents available... Please create a new document</p>
              )}
              {/* <Card></Card> */}
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default FileManagerDocs;
