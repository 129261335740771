import { NotificationManager } from "react-notifications";
import {
  _getAllConsultants,
  _createConsultant,
  _getConsultant
} from "../../api/ConsultantRequest";
import { GET_ALL_CONSULTANTS, GET_CONSULTANT } from "../types";

export const getAllConsultants = () => {
  return async dispatch => {
    try {
      const { data } = await _getAllConsultants();
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_ALL_CONSULTANTS, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const createConsultantAction = obj => {
  return async dispatch => {
    try {
      const { name, lg, rc, phone, email, address, type } = obj;

      const reqObj = {
        data: {
          name,
          lg,
          rc,
          phone,
          email,
          address,
          type
        }
      };
      const { data } = await _createConsultant(reqObj);
      console.log("success created ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        await dispatch(getAllConsultants());
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      return -1;
    }
  };
};

export const getConsultant = consultantID => {
  return async dispatch => {
    try {
      const { data } = await _getConsultant(consultantID);
      // console.log("success login ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_CONSULTANT, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};
