import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormRadio,
  FormGroup
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import WaterDetailsForm from "./WaterDetailsForm";

function WaterDetails() {
  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title=""
          subtitle="Water Details "
          className="text-sm-left mb-3"
        />
      </Row>

      <Row>
        <Col lg="12" className="mb-4">
          <WaterDetailsForm />
        </Col>
      </Row>
    </Container>
  );
}

export default WaterDetails;
