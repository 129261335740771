import React, { useEffect } from "react";
import {
  Col,
  Row,
  Form,
  FormInput,
  Button,
  FormTextarea,
  FormCheckbox
} from "shards-react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  assignModulesAction,
  createRoleAction
} from "../../store/Actions/RolesActions";

function AssignModule({ open, handleClose, rowData }) {
  const [loading, setLoading] = React.useState(false);
  const [moduleState, setModuleState] = React.useState({
    company: false,
    payments: false,
    consultants: false,
    reports: false,
    activities: false,
    admin: false,
    accesslevel: false
  });
  const dispatch = useDispatch();
  const apiModules = useSelector(state => state.Roles.rolemodules);
  console.log("api modules", rowData);

  const resetAccessState = () => {
    setModuleState({
      company: false,
      payments: false,
      consultants: false,
      reports: false,
      activities: false,
      admin: false,
      accesslevel: false
    });
  };

  useEffect(() => {
    if (open) {
      resetAccessState();
      console.log("closed");
    }
  }, [open]);

  useEffect(() => {
    let tempObj = {};
    console.log("hello", Object.values(moduleState));
    for (let i = 0; i < apiModules.length; i++) {
      for (const a in moduleState) {
        // console.log(`key: ${a}, value: ${moduleState[a]}`);
        if (apiModules[i].service == a) {
          //   tempArray.push({ a: true });
          tempObj[a] = true;
        }
      }
    }
    console.log("this is the final temp array", tempObj);
    setModuleState(prevObj => {
      return {
        ...prevObj,
        ...tempObj
      };
    });
  }, [apiModules]);

  const handleChangeModule = (e, module) => {
    const tempModuleState = {};
    tempModuleState[module] = !moduleState[module];
    console.log("hello two", tempModuleState);
    setModuleState(val => {
      return {
        ...val,
        ...tempModuleState
      };
    });
  };

  const handleAssignModule = async values => {
    setLoading(true);

    let reqModules = [];
    for (const a in moduleState) {
      if (moduleState[a] == true) {
        reqModules.push(a);
      }
    }

    const reqObj = {
      data: {
        roleID: rowData.id,
        tags: reqModules
      }
    };

    console.log("submitted modules", reqObj);
    const response = await dispatch(assignModulesAction(reqObj));
    if (response == 1) {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <h5>Assign Module</h5>{" "}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Formik
              initialValues={{
                company: false,
                description: ""
              }}
              validationSchema={yup.object({
                company: yup.boolean()
              })}
              onSubmit={values => {
                console.log("values", values);
                handleAssignModule(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form>
                  <Row className="mb-2">
                    <Col lg="12">
                      {/* <label htmlFor="company"> Company</label> */}
                      <FormCheckbox
                        name="company"
                        checked={moduleState.company}
                        onChange={e => {
                          handleChangeModule(e, "company");
                        }}
                        value={moduleState.company}
                      >
                        Company
                      </FormCheckbox>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="12">
                      <FormCheckbox
                        name="consultants"
                        checked={moduleState.consultants}
                        onChange={e => {
                          handleChangeModule(e, "consultants");
                        }}
                        value={moduleState.consultants}
                      >
                        Consultants
                      </FormCheckbox>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="12">
                      <FormCheckbox
                        name="reports"
                        checked={moduleState.reports}
                        onChange={e => {
                          handleChangeModule(e, "reports");
                        }}
                        value={moduleState.reports}
                      >
                        Reports
                      </FormCheckbox>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="12">
                      <FormCheckbox
                        name="payments"
                        checked={moduleState.payments}
                        onChange={e => {
                          handleChangeModule(e, "payments");
                        }}
                        value={moduleState.payments}
                      >
                        Payments
                      </FormCheckbox>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="12">
                      <FormCheckbox
                        name="activities"
                        checked={moduleState.activities}
                        onChange={e => {
                          handleChangeModule(e, "activities");
                        }}
                        value={moduleState.activities}
                      >
                        Activities Log
                      </FormCheckbox>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="12">
                      <FormCheckbox
                        name="admin"
                        checked={moduleState.admin}
                        onChange={e => {
                          handleChangeModule(e, "admin");
                        }}
                        value={moduleState.admin}
                      >
                        Admin
                      </FormCheckbox>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="12">
                      <FormCheckbox
                        name="accesslevel"
                        checked={moduleState.accesslevel}
                        onChange={e => {
                          handleChangeModule(e, "accesslevel");
                        }}
                        value={moduleState.accesslevel}
                      >
                        Access Level
                      </FormCheckbox>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="my-3" style={{ textAlign: "right" }}>
                      <Button outline theme="info" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        disabled={loading}
                        className="mx-2 "
                        onClick={handleSubmit}
                      >
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                        &nbsp; Assign
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default AssignModule;
