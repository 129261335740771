import React from "react";
import moment from "moment";
import { Col, Button } from "shards-react";
import CloseReportModal from "./CloseReportModal";
import ReportDetails from "./ReportDetails";
import { Link } from "react-router-dom";
import { baseUrl } from "../../api";

function ReportTicket({ data, index }) {
  const [closeReportModal, setCloseReportModal] = React.useState(false);
  const [viewReportModal, setViewReportModal] = React.useState(false);

  const toggleCloseReportModal = () => {
    setCloseReportModal(true);
  };

  const handleCloseModal = () => {
    setCloseReportModal(val => !val);
  };

  const toggleViewReportModal = () => {
    setViewReportModal(true);
  };

  const handleCloseReportModal = () => {
    setViewReportModal(val => !val);
  };
  return (
    <Col lg="6" key={index}>
      <CloseReportModal
        data={data}
        open={closeReportModal}
        handleClose={handleCloseModal}
      />
      <ReportDetails
        data={data}
        open={viewReportModal}
        handleClose={handleCloseReportModal}
      />
      <div
        className="py-2 px-3 my-1"
        style={{
          border: "1px solid black",
          borderRadius: 10,
          backgroundColor: "#E5E9CE"
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h5 className="p-0 m-0">{data.typeofcomplaint} </h5>
            <small>
              Reported by {data.fullname} on{" "}
              {moment(data.transDate * 1000).format("MMMM Do YYYY")}{" "}
            </small>
          </div>

          {data.status == 0 ? (
            <small className="p-0 m-0">Status: Open Report</small>
          ) : (
            <small>Status: Closed Report</small>
          )}
        </div>

        <p className="">{data.details}</p>
        <span className="p-0 m-0">
          Phone: +234{data.phone} | Email: {data.email}
        </span>
        <br />
        <small className="p-0 m-0">
          Place: {data.nameofplace} | Location: {data.location}
        </small>

        <div className="mt-2">
          {data.status == 0 ? (
            <Button
              style={{
                backgroundColor: "#11652F",
                color: "white",
                border: "none"
              }}
              onClick={toggleCloseReportModal}
            >
              Close Report
            </Button>
          ) : (
            <Button onClick={toggleViewReportModal}>View Report Details</Button>
          )}
          {/* <a href={`${baseUrl}/${data.image}`} target="_blank" className="ml-2">
            View Image
          </a> */}
        </div>
      </div>
    </Col>
  );
}

export default ReportTicket;
