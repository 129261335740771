import React from "react";
import { Nav } from "shards-react";
import { withRouter } from "react-router-dom";
import SubSidebarNavItem from "./SubSidebarNavItem";
import { Store } from "../../../flux";

class SubSidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: Store.getSubSidebarItems(),

      showSubCat: false
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);

    //this is to control the nav items that display of the sidebar.
    // if the route contains "details", it should show navitems with submenu set as true
    // else it should show navitem with submenu set as undefined.
    //
    // Route is checked to update the state
    // let pathname = this.props.location.pathname;
    // if (pathname.includes("details")) {
    //   this.setState({
    //     ...this.state,
    //     showSubCat: true
    //   });
    // } else {
    //   this.setState({
    //     ...this.state,
    //     showSubCat: false
    //   });
    // }
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSubSidebarItems()
    });
  }

  render() {
    const { navItems: items } = this.state;
    // console.log("are you here", this.props);

    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SubSidebarNavItem
              key={idx}
              item={item}
              match={this.props.match}
              to={`${this.props.match.url}`}
            />
          ))}
        </Nav>
      </div>
    );
  }
}

// .filter(item => {
//     if (this.state.showSubCat) {
//       return item.submenu == true;
//     } else {
//       return typeof item.submenu == "undefined";
//     }
//   })

export default withRouter(SubSidebarNavItems);
