import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import AdminReducer from "./AdminReducer";
import CompanyReducer from "./CompanyReducer";
import ActivitiesLogReducer from "./ActivitiesLogReducer";
import DocumentsReducer from "./DocumentsReducer";
import PaymentsReducer from "./PaymentsReducer";
import ReportReducer from "./ReportReducer";
import ConsultantReducer from "./ConsultantReducer";
import RolesReducer from "./RolesReducer";

const reducers = combineReducers({
  Auth: AuthReducer,
  Admin: AdminReducer,
  Companies: CompanyReducer,
  Logs: ActivitiesLogReducer,
  Documents: DocumentsReducer,
  Payments: PaymentsReducer,
  Reports: ReportReducer,
  Consultants: ConsultantReducer,
  Roles: RolesReducer
});

export default reducers;
