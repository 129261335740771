import { GET_ALL_CONSULTANTS, GET_CONSULTANT } from "../types";

const initialState = {
  allConsultants: [],
  consultant: {}
};

export default function(state = initialState, actions) {
  switch (actions.type) {
    case GET_ALL_CONSULTANTS:
      return {
        ...state,
        allConsultants: actions.payload
      };
    case GET_CONSULTANT:
      return {
        ...state,
        consultant: actions.payload[0]
      };
    default: {
      return state;
    }
  }
}
