import React, { useState } from "react";
import { Card, CardHeader, CardBody, Col, Button } from "shards-react";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { deleteFileAction } from "../../../store/Actions/DocumentsActions";
import FileSaver from "file-saver";
import DownLoadLink from "react-download-link";
import DownLoadFile from "react-downloader-file";
import { baseUrl } from "../../../api";

function ImageCard({ data, index }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // console.log("data daya", data);
  const handleDeleteFile = async obj => {
    setLoading(true);
    const res = window.confirm("Are you sure you want to delete this file?");
    if (res) {
      const response = await dispatch(
        deleteFileAction({ id: data.id, url: data.url })
      );
      if (response) setLoading(false);
    }
  };

  const handleDownload = fileUrl => {
    // FileSaver.saveAs(`${fileUrl}`);

    fetch(fileUrl, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "filedownloaded");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log("fetch url error", err);
      });
  };

  return (
    <Col md="4" key={index}>
      <div
        className="p-2 m-1"
        style={{
          position: "relative",
          borderRadius: 10,
          backgroundColor: "#E5E9CE"
        }}
        key={index}
      >
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Delete File</Tooltip>}
        >
          <MdDelete
            style={{
              cursor: "pointer",
              color: "red",
              position: "absolute",
              top: 10,
              right: 10
            }}
            onClick={handleDeleteFile}
          >
            {loading && <Spinner variant="grow" />}
          </MdDelete>
        </OverlayTrigger>

        <div>
          <div>
            <Link
              to={{ pathname: `${baseUrl}/${data.url}` }}
              target="_blank"
              className="p-0 m-0 "
              style={{ fontSize: "1.5rem", color: "#495057" }}
              // download={data.url}
              onClick={() => handleDownload(`${baseUrl}/${data.url}`)}
            >
              {data.docName}
            </Link>

            <p> {data.note}</p>
          </div>
          <p className="p-0 m-0" style={{ fontSize: ".9rem" }}>
            Created by {data.creator} at{" "}
            {moment(data.dateCreated * 1000).format("DD/MM/YYYY")}
          </p>
        </div>
      </div>
    </Col>
  );
}

export default ImageCard;
