import React, { useState } from "react";
import {
  Container,
  Row,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  FormInput,
  Col
} from "shards-react";
import { Spinner } from "react-bootstrap";
import logo from "../../images/avatars/lasepa-logo.png";
import { Formik, validateYupSchema } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { authActions, signInAction } from "../../store/Actions/AuthActions";

function Signin() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const authresult = useSelector(state => state.Auth.authenticated);

  console.log("auth result ", authresult);
  const handleSignin = async values => {
    setLoading(true);
    // window.location.pathname = "/industry-manager";
    const response = await dispatch(signInAction(values));
    if (response === 1) {
      setLoading(false);
      window.location.pathname = "/company-manager";
    } else {
      setLoading(false);
    }
  };
  return (
    <Container
      style={{ height: "100vh" }}
      fluid
      className="d-flex justify-content-center align-items-center"
    >
      <Card>
        <CardHeader style={{ textAlign: "center" }}>
          <img src={logo} style={{ maxWidth: "250px" }} />
        </CardHeader>
        <CardBody>
          <CardTitle className="text-center">Admin Dashboard</CardTitle>

          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={yup.object({
              username: yup.string().required("Username is required"),
              password: yup.string().required("Password is required")
            })}
            onSubmit={values => {
              console.log("login credentials ", values);
              handleSignin(values);
            }}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              values,
              errors,
              touched
            }) => (
              <>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="username">Username</label>
                    <FormInput
                      id="username"
                      type="text"
                      onChange={handleChange("username")}
                      onBlur={handleBlur("username")}
                      value={values.username}
                      placeholder="Username"
                      style={{ fontSize: "1rem" }}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.username && errors.username}{" "}
                    </small>
                  </Col>
                  <Col md="12">
                    <label htmlFor="password">Password</label>
                    <FormInput
                      id="password"
                      type="password"
                      placeholder="Password"
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      value={values.password}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.password && errors.password}{" "}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-4 text-right">
                    <Button
                      disabled={loading}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      {loading && (
                        <Spinner
                          animation="border"
                          variant="secondary"
                          size="sm"
                        />
                      )}
                      &nbsp; Signin
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Container>
  );
}

export default Signin;
