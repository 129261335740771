import { GET_ALL_LOGS } from "../types";

const initialState = {
  allLogs: []
  //   company: null
};

export default function(state = initialState, actions) {
  switch (actions.type) {
    case GET_ALL_LOGS:
      return {
        ...state,
        allLogs: actions.payload
      };
    default: {
      return state;
    }
  }
}
