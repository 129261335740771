import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoles, getRoleModules } from "../../store/Actions/RolesActions";
import CreateRole from "./CreateRole";
import MaterialTable from "material-table";
import UpdateRole from "./UpdateRole";
import AssignModule from "./AssignModule";

function Roles() {
  const dispatch = useDispatch();
  const [openCreateRole, setOpenCreateRole] = useState(false);
  const [openUpdateRole, setOpenUpdateRole] = useState(false);
  const [openAssignModule, setOpenAssignModule] = useState(false);
  const [updateDataId, setUpdateDataId] = useState("");
  const [currentModuleData, setCurrentModuleData] = useState(null);

  const roles = useSelector(state => state.Roles.roles);

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const toggleOpenCreateRole = () => {
    setOpenCreateRole(true);
  };

  const handleClose = () => {
    setOpenCreateRole(val => !val);
  };

  const toggleUpdateModal = id => {
    setOpenUpdateRole(true);
    setUpdateDataId(id);
  };

  const handleCloseUpdate = () => {
    setOpenUpdateRole(val => !val);
  };

  const toggleAssignModule = rowData => {
    setOpenAssignModule(true);
    setCurrentModuleData(rowData);
  };

  const handleCloseAssignModule = () => {
    setOpenAssignModule(val => !val);
    // resetAccessState()
  };

  return (
    <Container fluid className="main-content-container px-4">
      <CreateRole open={openCreateRole} handleClose={handleClose} />
      <UpdateRole
        open={openUpdateRole}
        handleClose={handleCloseUpdate}
        id={updateDataId}
      />
      <AssignModule
        open={openAssignModule}
        handleClose={handleCloseAssignModule}
        rowData={currentModuleData}
      />

      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Access Level"
          subtitle="Assign access level to users"
          className="text-sm-left mb-3"
        />
      </Row>

      <Row className="d-flex justify-content-end mr-1 my-2">
        <Button
          theme="primary"
          className="mr-1  d-flex align-items-center"
          onClick={toggleOpenCreateRole}
        >
          <i
            className="material-icons text-size-2"
            style={{ fontSize: "1.2rem" }}
          >
            add
          </i>
          Create Role
        </Button>
      </Row>

      <Row className="mb-3">
        <Col lg="12">
          <MaterialTable
            title="Access Control"
            columns={[
              // { title: "S/N", field: "num", filtering: false },
              {
                title: "Role Name",
                field: "roleName",
                filterPlaceholder: "Search"
              },
              { title: "Description", field: "description", filtering: false },
              { title: "Creator", field: "creator" }
            ]}
            data={roles}
            options={{
              headerStyle: {
                backgroundColor: "#01579b",
                color: "#FFF"
              },
              filtering: false,
              actionsColumnIndex: -1
            }}
            actions={[
              {
                icon: () => <Button>Update</Button>,
                tooltip: "Update Role",
                onClick: (event, rowData) => {
                  toggleUpdateModal(rowData.id);
                }
              },
              {
                icon: () => <Button>Add Access</Button>,
                tooltip: "Add Access",
                onClick: async (event, rowData) => {
                  dispatch(getRoleModules(rowData.id));
                  toggleAssignModule(rowData);
                }
              }
            ]}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Roles;
