import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Container, Row, Button } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import IndustryManagerList from "../../components/industry-manager/IndustryManagerList";
import AddIndustry from "./AddIndustry";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompanies } from "../../store/Actions/CompanyActions";

const data = [
  {
    backgroundImage: require("../../images/content-management/7.jpeg"),
    lga: "IKD",
    address: "2, Durosimi Street, Somolu, Lagos State",
    opYear: "29 February 2021",
    coopYear: "29 January 2021",
    companyName: "Plastic Company",
    description:
      "Pursuit chamber as elderly amongst on. Distant however warrant farther to of. My justice wishing prudent waiting in be...",
    sector: "Domestic and Industrial Plastics and Rubber",
    id: 1
  },
  {
    backgroundImage: require("../../images/content-management/8.jpeg"),
    lga: "SU",
    address: "12, Island Street, Somolu, Lagos State",
    opYear: "29 February 2021",
    coopYear: "29 January 2021",
    companyName: "Food Company",
    description:
      "Pursuit chamber as elderly amongst on. Distant however warrant farther to of. My justice wishing prudent waiting in be...",
    sector: "Food, Beverages and Tobacco",
    id: 2
  },
  {
    backgroundImage: require("../../images/content-management/9.jpeg"),
    lga: "AL",
    address: "12, Ebute Meta Street, Yaba, Lagos State",
    opYear: "29 February 2021",
    coopYear: "29 January 2021",
    companyName: "Wood Company",
    description:
      "Pursuit chamber as elderly amongst on. Distant however warrant farther to of. My justice wishing prudent waiting in be...",
    sector: "Wood Products (Including Furniture)",
    id: 3
  },
  {
    backgroundImage: require("../../images/content-management/10.jpeg"),
    lga: "AM",
    address: "12, Eko Idumota Street, Ajah, Lagos State",
    opYear: "29 February 2021",
    coopYear: "29 January 2021",
    companyName: "MTN Communications",
    description:
      "Pursuit chamber as elderly amongst on. Distant however warrant farther to of. My justice wishing prudent waiting in be...",
    sector: "Telecommunications",
    id: 4
  }
];

const IndustryManager = ({ match }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const companies = useSelector(state => state.Companies.allcompanies);
  console.log("match mathc", match);
  const toggleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const toggleAddModal = () => {
    console.log("hello");
    setOpenAddModal(val => !val);
  };

  useEffect(() => {
    async function getCompanies() {
      setLoading(true);
      const response = await dispatch(getAllCompanies());
      if (response) setLoading(false);
    }
    getCompanies();
  }, []);

  // localStorage.removeItem("activeID");
  return (
    <Container fluid className="main-content-container px-4">
      <AddIndustry
        open={openAddModal}
        toggle={toggleAddModal}
        handleClose={toggleAddModal}
      />
      {/* Page Header */}
      <Row noGutters className="page-header pt-2">
        <PageTitle
          title="Company Manager"
          subtitle="Registered Companies"
          className="text-sm-left"
        />
      </Row>

      <Row className="d-flex justify-content-end mr-1 my-2">
        <Button
          theme="primary"
          className="mr-1  d-flex align-items-center"
          onClick={toggleOpenAddModal}
        >
          <i
            className="material-icons text-size-2"
            style={{ fontSize: "1.2rem" }}
          >
            add
          </i>
          Add Company
        </Button>
      </Row>

      <Row>
        {!loading ? (
          <IndustryManagerList data={companies} />
        ) : (
          <Spinner animation="border" />
        )}
      </Row>
    </Container>
  );
};

export default IndustryManager;
