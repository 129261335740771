import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  // Modal,
  // ModalHeader,
  // ModalBody,
  Form,
  FormInput,
  Button,
  FormTextarea,
  FormSelect
  // ModalFooter
} from "shards-react";
import { useDispatch } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import moment from "moment";

function ReportDetails({ open, handleClose, data }) {
  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        {" "}
        <h4>Report Details</h4>{" "}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>
              Report closed by {data.responder} on{" "}
              {moment(data.dateClosed * 1000).format("MMMM Do, YYYYY")}{" "}
            </p>
            <p>Reason: {data.comment}</p>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ReportDetails;
