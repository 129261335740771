import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormRadio,
  FormGroup
} from "shards-react";
import { useRouteMatch } from "react-router-dom";
import PageTitle from "../../../components/common/PageTitle";
import CompleteFormExample from "../../../components/components-overview/CompleteFormExample";
import ProductionDetailsForm from "./ProductionDetailsForm";

function ProductionDetails() {
  const loc = useRouteMatch();

  console.log("this goddamn loc", loc);
  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title=""
          subtitle="Production Details and Material Balance"
          className="text-sm-left mb-3"
        />
      </Row>

      <Row>
        <Col lg="12" className="mb-3">
          <ProductionDetailsForm />
        </Col>
      </Row>
    </Container>
  );
}

export default ProductionDetails;
