import React from "react";
import { CardHeader } from "shards-react";
import { Tabs, Tab } from "react-bootstrap";
// import EmployeeInfoTab from "./EmployeeInfoTab";
// import MedicalPartnerInfoTab from "./MedicalPartnerInfoTab";
import ConsultantInfoTab from "./ConsultantInfoTab";
import SummaryTab from "../../components/industry-manager/SummaryTab";
import { useSelector } from "react-redux";
import FileManagerDocs from "./FileManagerDocs";
import FileManagerPreview from "./FileManagerPreview";
import ConsultantInvoice from "./ConsultantInvoice";

const ConsultantProfileDetails = ({ consultantDetails }) => {
  const consultant = useSelector(state => state.Consultants.consultant);
  return (
    <CardHeader small className="my-4">
      <Tabs defaultActiveKey="consInfo">
        <Tab
          eventKey="consInfo"
          title="Consultant Information"
          tabClassName="font-weight-bold p-2"
        >
          <ConsultantInfoTab data={consultantDetails} />
        </Tab>
        <Tab
          eventKey="docManager"
          title="Documents Manager"
          tabClassName="font-weight-bold p-2"
        >
          <FileManagerDocs />
        </Tab>
        <Tab
          eventKey="prevDocs"
          title="Preview Documents"
          tabClassName="font-weight-bold p-2"
        >
          <FileManagerPreview data={consultant} />
        </Tab>
        <Tab
          eventKey="invoice"
          title="Invoice"
          tabClassName="font-weight-bold p-2 text-center"
        >
          <ConsultantInvoice />
        </Tab>

        <Tab
          eventKey="summary"
          title="Summary"
          tabClassName="font-weight-bold p-2 text-center"
        >
          {<SummaryTab data={consultant} />}
        </Tab>
      </Tabs>
    </CardHeader>
  );
};

export default ConsultantProfileDetails;
