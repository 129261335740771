import React, { useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import { useLocation, useParams } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentsInvoice } from "../../store/Actions/PaymentsActions";
import Invoice from "./Invoice";
import { Spinner } from "react-bootstrap";

const InvoiceList = ({ payments }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const coyInvoice = useSelector(state => state.Payments.coyInvoice);

  console.log("coy invoice", coyInvoice);

  useEffect(() => {
    async function callGetInvoice() {
      await dispatch(getPaymentsInvoice(id));
    }
    callGetInvoice();
  }, []);
  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title=""
          subtitle="Invoice Details"
          className="text-sm-left mb-2"
        />
      </Row>

      <Row>
        {coyInvoice.length > 0 ? (
          coyInvoice.map((item, index) => {
            return <Invoice invoice={item} index={index} />;
          })
        ) : (
          <Spinner animation="border" />
        )}
      </Row>
    </Container>
  );
};

export default InvoiceList;
