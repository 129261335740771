import { GET_PAYMENTS, GET_SINGLE_PAYMENT } from "../types";

const initialState = {
  payments: [],
  coyInvoice: []
};

export default function(state = initialState, actions) {
  switch (actions.type) {
    case GET_PAYMENTS:
      return {
        ...state,
        payments: actions.payload
      };
    case GET_SINGLE_PAYMENT:
      return {
        ...state,
        coyInvoice: actions.payload
      };
    default: {
      return state;
    }
  }
}
