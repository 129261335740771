import { GET_REPORTS } from "../types";
import { NotificationManager } from "react-notifications";
import { _closeReport, _getReports } from "../../api/ReportsRequest";

export const getAllReports = () => {
  return async dispatch => {
    try {
      const { data } = await _getReports();
      console.log("payments ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_REPORTS, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const closeReport = obj => {
  return async dispatch => {
    try {
      const { reportID, comment, responder } = obj;

      const reqObj = {
        data: {
          reportID,
          comment,
          responder
        }
      };
      const { data } = await _closeReport(reqObj);
      console.log("payments ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch(getAllReports());
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};
