import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  FormRadio
} from "shards-react";
import * as Yup from "yup";
import { Formik, FieldArray, getIn } from "formik";
import { FiPlusCircle, FiMinusCircle, FiDivide } from "react-icons/fi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const WarehouseDetailsForm = ({ state }) => {
  return (
    <Card small className="">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Production Details and Material Balance</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Formik
                initialValues={{
                  goodsType: "",
                  warehouseSize: ""
                }}
                validationSchema={Yup.object({
                  goodsType: Yup.string(),
                  warehouseSize: Yup.string()
                })}
                onSubmit={values => {
                  console.log("form submit", values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <Form>
                    <Row>
                      <Col md="4" className="form-group">
                        <label htmlFor="goodsType">
                          A. &nbsp; Type of Goods Warehoused
                        </label>
                      </Col>
                      <Col md="8">
                        <FormInput
                          id="goodsType"
                          placeholder="Eg: Electronics, Pharmaceuticals, Equipment etc"
                          value={values.goodsType}
                          onChange={handleChange("goodsType")}
                          onBlur={handleBlur("goodsType")}
                        />
                        <small className="text-danger">
                          {" "}
                          {touched.goodsType && errors.goodsType}{" "}
                        </small>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md="4">
                        <label htmlFor="warehouseSize">
                          B. &nbsp; Size of Warehouse
                        </label>
                      </Col>

                      <Col md="8">
                        <FormInput
                          id="warehouseSize"
                          placeholder="Size of Warehouse in Sq/m"
                          value={values.warehouseSize}
                          onChange={handleChange("warehouseSize")}
                          onBlur={handleBlur("warehouseSize")}
                        />
                        <small className="text-danger">
                          {" "}
                          {touched.warehouseSize && errors.warehouseSize}{" "}
                        </small>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12" className="text-right">
                        <Button
                          className="my-3"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                        <Button
                          outline
                          className="my-3 mx-2"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Preview Details
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};
WarehouseDetailsForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

WarehouseDetailsForm.defaultProps = {
  title: "Account Details"
};

export default WarehouseDetailsForm;
