import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Progress,
  Row,
  Button
} from "shards-react";
// import { Image } from "bootstrap";
import avatar from "../../images/avatars/avatar.jpg";

const AdminProfile = ({ adminProfile }) => {
  return (
    <Card small className="my-2 pt-3">
      <CardHeader className="border-bottom text-center">
        <h3>Admin Profile</h3>
        <div className="mb-3 mx-auto">
          {/* <Image src="holder.js/171x180" roundedCircle /> */}
          <img
            className="rounded-circle"
            src={avatar}
            alt={adminProfile.companyName}
            width="110"
            height="110"
          />
        </div>
        <h4 className="mb-0">{adminProfile.companyName}</h4>
        <span className="text-muted d-block mb-2">
          {adminProfile.description}
        </span>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem
          style={{ display: "flex", justifyContent: "center" }}
          className="px-4"
        >
          <div>
            <strong className="text-muted d-block">Fullname:</strong>
            <h5>{adminProfile.fullname}</h5>
          </div>
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            <strong className="d-block">Username</strong>
            <h5>{adminProfile.username} </h5>
          </div>
          <div>
            <strong className="d-block">Phone number</strong>
            <h5>{adminProfile.phone} </h5>
          </div>
        </ListGroupItem>
        <ListGroupItem className="px-4"></ListGroupItem>
      </ListGroup>
      {/* <CardFooter>
        <Button className="mr-1">Edit Profile</Button>
        <Button>Preview Profile</Button>
      </CardFooter> */}
    </Card>
  );
};
AdminProfile.propTypes = {
  /**
   * The user details object.
   */
  adminProfile: PropTypes.object
};

AdminProfile.defaultProps = {
  adminProfile: {
    name: "Sierra Brooks",
    avatar: require("../../images/avatars/0.jpg"),
    jobTitle: "Project Manager",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Description",
    metaValue:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?"
  }
};

export default AdminProfile;
