import { GET_PAYMENTS, GET_SINGLE_PAYMENT } from "../types";
import { NotificationManager } from "react-notifications";
import {
  _getInvoice,
  _getAllInvoice,
  _createInvoice,
  _updateInvoice,
  _viewProof
} from "../../api/PaymentRequest";

export const getAllPaymentsInvoice = () => {
  return async dispatch => {
    try {
      const { data } = await _getAllInvoice();
      console.log("payments ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_PAYMENTS, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const getPaymentsInvoice = companyID => {
  return async dispatch => {
    try {
      const { data } = await _getInvoice(companyID);
      console.log("payments ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_SINGLE_PAYMENT, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const createInvoiceAction = obj => {
  return async dispatch => {
    try {
      const { billName, companyID, amount, details, toBill } = obj;

      const reqObj = {
        data: {
          billName,
          companyID,
          amount,
          details,
          toBill
        }
      };

      const { data } = await _createInvoice(reqObj);
      console.log("success created ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        // NotificationManager.success(data.success.message, "Success");
        dispatch(getAllPaymentsInvoice());
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const updateInvoiceAction = obj => {
  return async dispatch => {
    try {
      const { billID, status } = obj;

      const reqObj = {
        data: {
          billID,
          status
        }
      };

      const { data } = await _updateInvoice(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        // NotificationManager.success(data.success.message, "Success");
        dispatch(getAllPaymentsInvoice());
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const viewProofAction = refID => {
  return async dispatch => {
    try {
      const { data } = await _viewProof(refID);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        // NotificationManager.success(data.success.message, "Success");
        console.log("link 000 data", data);
        return data.content.data;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};
