import { GET_ROLES, GET_ROLES_MODULE } from "../types";

const initialState = {
  roles: [],
  rolemodules: []
};

export default function(state = initialState, actions) {
  switch (actions.type) {
    case GET_ROLES:
      return {
        ...state,
        roles: actions.payload
      };
    case GET_ROLES_MODULE:
      return {
        ...state,
        rolemodules: actions.payload
      };
    default: {
      return state;
    }
  }
}
